import { graphql, useStaticQuery } from 'gatsby';

export const useDefaultWomanProfileImage = () => {
  const { image } = useStaticQuery(graphql`
    {
      image: file(relativePath: { eq: "fallbacks/silhouette-woman.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return image.childImageSharp.fluid;
};
