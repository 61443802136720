import { Link } from 'gatsby';
import React from 'react';
import { elastic as Menu } from 'react-burger-menu';

// Styles
import './styles.css';

// Icon
import { ExternalLink } from 'components/icons';

// Hooks
import { useNavQuery } from 'hooks';
import { colors } from 'styles';
import { AuthMenu } from '../AuthMenu';

export const BurgerMenu = () => {
  const { totalCount } = useNavQuery();

  return (
    <Menu>
      <ul>
        <AuthMenu />
        <li>
          <Link className="menu-item" to="/">
            Home
          </Link>
        </li>
        <li>
          <label htmlFor="coaches-corner">Coaches Corner</label>
          <ul>
            <li value="about">
              <Link to="/about">About</Link>
            </li>
            <li value="our-team">
              <Link to="/our-team">Our Team</Link>
            </li>
            <li value="bylaws">
              <Link to="/bylaws">Bylaws</Link>
            </li>
            <li value="join-us">
              <Link to="/join-us">Join Us</Link>
            </li>
            <li value="current-members">
              <Link to="/current-members">Current Members</Link>
            </li>
            <li value="meeting-minutes">
              <Link to="/meeting-minutes">Meeting Minutes</Link>
            </li>
            <li>
              <Link to="/forms">MSTCA Forms</Link>
            </li>
            {totalCount && totalCount > 0 && (
              <li value="tags/coaching-vacancies">
                <Link to="/tags/coaching-vacancies">Coaching Vacancies</Link>
              </li>
            )}
            <a
              href="https://forms.gle/s39FPrvC6d7MCYst5"
              rel="noopener noreferrer"
              target="_blank"
            >
              Contact Us
              <ExternalLink
                fill={colors.white}
                style={{ margin: '-4px 5px 0' }}
              />
            </a>
          </ul>
        </li>
        <li>
          <label htmlFor="coaches-corner">Calendar/Results</label>
          <ul>
            <li>
              <Link className="menu-item" to="/calendar">
                Calendar/Results
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <label htmlFor="records">Records/Champions</label>
          <ul>
            <li>
              <Link to="/champions/cross-country">Cross country champions</Link>
            </li>
            <li>
              <Link to="/records/indoor-track">Indoor track records</Link>
            </li>
            <li>
              <Link to="/records/outdoor-track">Outdoor track records</Link>
            </li>
            <li>
              <Link to="/hall-of-fame/athletes">Athletes Hall of Fame</Link>
            </li>
            <li>
              <Link to="/hall-of-fame/coaches">Coaches Hall of Fame</Link>
            </li>
          </ul>
        </li>
        <li>
          <label htmlFor="awards">Scholarships/Clinics</label>
          <ul>
            <li>
              <Link to="/campership/robert-mcintyre">
                Robert McIntyre Campership
              </Link>
            </li>
            <li>
              <Link to="/scholarship/mcintyre-polizzotto">
                Mcintyre/Polizzotto Scholarship
              </Link>
            </li>
            <li>
              <Link to="/camps-and-clinics/new-england-track-field-clinic">
                New England Track & Field Clinic
              </Link>
            </li>
            <li>
              <Link to="/camps-and-clinics/do-it-clinic">"Do it clinic"</Link>
            </li>
          </ul>
        </li>
        <li>
          <label htmlFor="archives">Archives</label>
          <ul>
            <li>
              <Link className="menu-item" to="/posts">
                Article Archives
              </Link>
            </li>
            <li>
              <a
                href="https://www.mstca.net"
                rel="noopener noreferrer"
                target="_blank"
              >
                Legacy MSTCA
                <ExternalLink
                  fill={colors.white}
                  style={{ margin: '-4px 5px 0' }}
                />
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </Menu>
  );
};
