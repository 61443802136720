import React from 'react';
import { Link } from 'gatsby';

// Local Styles
import { SiteFooter, SiteFooterContent, SiteFooterNav } from './local-styles';

// Styles
import { inner, outer } from 'styles';

// Misc
import config from '../../website-config';

export const Footer = () => {
  return (
    <footer css={[outer, SiteFooter]}>
      <div css={[inner, SiteFooterContent]}>
        <section className="copyright">
          <Link to="/">{config.title}</Link> &copy; {new Date().getFullYear()}{' '}
          {config.footer && (
            <Link to="/">
              | {config.title} {config.footer}
            </Link>
          )}
        </section>
        <SiteFooterNav>
          <a href={config.joinUs} target="_blank" rel="noopener noreferrer">
            Join us
          </a>
          {config.facebook && (
            <a href={config.facebook} target="_blank" rel="noopener noreferrer">
              Facebook
            </a>
          )}
          {config.twitter && (
            <a href={config.twitter} target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
          )}
          {config.instagram && (
            <a
              href={config.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          )}
          <a
            href="https://www.mstca.net"
            rel="noopener noreferrer"
            target="_blank"
          >
            Archives
          </a>
        </SiteFooterNav>
      </div>
    </footer>
  );
};
