import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { lighten } from 'polished';

import { colors } from 'styles';
import { TableVariants } from 'templates';

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const RowStyles = css`
  text-align: left;
  padding: 8px;
  display: table-cell;
  vertical-align: middle;
  color: ${colors.black};
`;

export const DoubleRow = css`
  display: flex;
  align-items: center;
`;

export const AlignColumn = styled.div<{ direction?: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  margin-left: 10px;
`;

export const TableData = styled.td<{ center?: boolean }>`
  ${RowStyles};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  &.withImage {
    align-items: center;
    display: flex;
    > img {
      margin-right: 0.625rem;
    }
  }
`;

export const TableHeader = styled.th`
  ${RowStyles};
  font-weight: 600;
  background-color: ${colors.offgrey};
  color: ${colors.white};
  height: 70px;
  vertical-align: middle;
`;

export const TableRow = styled.tr<{
  isNegative?: boolean;
  variant?: TableVariants;
}>`
  background-color: ${({ isNegative }) => isNegative && colors.red};
  &:nth-of-type(even) {
    background-color: #eee;
    background-color: ${({ isNegative }) =>
      isNegative && lighten(0.1, colors.red)});
    background-color: ${({ variant }) => variant === 'dark' && colors.darkgrey};
`;
