import styled from '@emotion/styled';
import { ReadNextCardStylesProps } from './index';

import { colors } from 'styles';

export const ReadNextCardStyles = styled.article<ReadNextCardStylesProps>`
  position: relative;
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 20px 40px;
  padding: 25px;
  color: ${colors.white};
  background: ${colors.darkgrey} center center;
  background-size: cover;
  border-radius: 5px;
  box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px,
    rgba(39, 44, 49, 0.03) 1px 3px 8px;
  background-image: url(${props => props.coverImage});
  background-position-y: bottom;

  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: 5px;
    backdrop-filter: blur(2px);
  }
`;

export const ReadNextCardHeader = styled.header`
  position: relative;
  z-index: 50;
  padding-top: 20px;
  text-align: center;
`;

export const ReadNextCardHeaderSitetitle = styled.small`
  display: block;
  font-size: 1.3rem;
  line-height: 1.3em;
  opacity: 0.8;
`;

export const ReadNextCardHeaderTitle = styled.h3`
  margin: 0;
  padding: 0 20px;
  color: ${colors.white};
  font-size: 3rem;
  line-height: 1.2em;
  letter-spacing: 1px;

  a {
    color: ${colors.white};
    font-weight: 300;
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }
`;

export const ReadNextDivider = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 80px;

  svg {
    width: 40px;
    fill: transparent;
    stroke: ${colors.white};

    stroke-width: 0.5px;
    stroke-opacity: 0.65;
  }
`;

export const ReadNextCardContent = styled.div`
  position: relative;
  z-index: 50;
  flex-grow: 1;
  display: flex;
  font-size: 1.7rem;

  ul {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    list-style: none;
    li {
      margin: 0;
      padding: 0;
      font-size: 1.6rem;
      line-height: 1.25em;
      font-weight: 200;
      letter-spacing: -0.5px;

      &:first-of-type a {
        padding-top: 10px;
      }
      &:last-of-type a {
        border-bottom: 0;
      }
      a {
        display: block;
        padding: 20px 0;
        border-bottom: rgba(255, 255, 255, 0.3) 1px solid;
        color: ${colors.white};
        font-weight: 500;
        vertical-align: top;
        transition: opacity 0.3s ease;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
`;

export const ReadNextCardFooter = styled.footer`
  position: relative;
  margin: 15px 0 3px 0;
  text-align: center;

  a {
    color: ${colors.white};
  }
`;
