import { graphql, useStaticQuery } from 'gatsby';

export const useMemberHero = () => {
  const { hero } = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "track-stopwatch.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return hero.childImageSharp.fluid;
};
