import { Link } from 'gatsby';
import get from 'lodash/get';
import React, { ReactElement } from 'react';

// Components
import { FluidImage } from 'components/PostCard/FluidImage';
import { ConditionalWrapper } from 'components';

// Local Styles
import {
  AuthorList,
  AuthorListItem,
  AuthorNameTooltip,
  PostCardContent,
  PostCardContentLink,
  PostCardExcerpt,
  PostCardImage,
  PostCardImageLink,
  PostCardMeta,
  PostCardStyles,
  PostCardTags,
  PostCardTitle,
  ReadingTime,
  StaticAvatar,
} from './local-styles';

// Shared Styles
import { AuthorProfileIcon } from 'styles';

// Misc.
import { getInitials } from 'utils';

// Types
import { Post } from './types';

export interface Props {
  isAdvertisement?: boolean;
  post: Post;
  postId: number;
}

export const PostCard = ({
  isAdvertisement = false,
  post,
  postId,
}: Props): ReactElement => {
  const createdDate = get(post, 'created_date');
  const excerpt = get(post, 'excerpt', '') || '';
  const firstName = get(post, 'author[0].data.first_name', '') || '';
  const fluid =
    get(post, 'attachments.localFiles[0].childImageSharp.fluid', {}) || {};
  const fullName = get(post, 'author[0].data.full_name', '') || '';
  const memberSlug = get(post, 'author[0].data.member_slug', '') || '';
  const tags = get(post, 'tags', []) || [];
  const timeToRead = get(post, 'time_to_read', '') || '';
  const title = get(post, 'title', '') || '';

  // Advertisement getters.
  const advertisementImage =
    (isAdvertisement &&
      get(
        post,
        'advertisements[0].data.attachments.localFiles[0].childImageSharp.fluid',
        {}
      )) ||
    {};
  const advertisementLink =
    (isAdvertisement && get(post, 'advertisements[0].data.external_url')) || '';

  // Decide routes/images based on if it is an ad or not.
  const link = isAdvertisement ? advertisementLink : `/post/${postId}`;
  const image = isAdvertisement ? advertisementImage : fluid;

  const linkImage = () => (
    <a
      className="post-card-image-link"
      css={PostCardImageLink}
      href={link}
      target="_blank"
    >
      <PostCardImage className="post-card-image">
        <FluidImage alt={title} fluid={image} />
      </PostCardImage>
    </a>
  );

  const linkContent = () => (
    <a
      className="post-card-link"
      css={PostCardContentLink}
      href={link}
      target="_blank"
    >
      <header className="post-card-header">
        {tags && tags.length > 0 && <PostCardTags>{tags[0]}</PostCardTags>}
        <PostCardTitle>{title}</PostCardTitle>
      </header>
      <PostCardExcerpt>
        <p>{excerpt}</p>
      </PostCardExcerpt>
    </a>
  );

  return (
    <article className="post-card" css={PostCardStyles}>
      <ConditionalWrapper condition={isAdvertisement} wrapper={linkImage}>
        <Link
          className="post-card-image-link"
          css={PostCardImageLink}
          to={link}
        >
          <PostCardImage className="post-card-image">
            <FluidImage alt={title} fluid={image} />
          </PostCardImage>
        </Link>
      </ConditionalWrapper>
      <PostCardContent className="post-card-content">
        <ConditionalWrapper condition={isAdvertisement} wrapper={linkContent}>
          <Link
            className="post-card-content-link"
            css={PostCardContentLink}
            to={link}
          >
            <header className="post-card-header">
              {tags && tags.length > 0 && (
                <PostCardTags>{tags[0]}</PostCardTags>
              )}
              <PostCardTitle>{title}</PostCardTitle>
            </header>
            <PostCardExcerpt>
              <p>{excerpt}</p>
            </PostCardExcerpt>
          </Link>
        </ConditionalWrapper>
        <PostCardMeta>
          <AuthorList>
            <AuthorListItem>
              <AuthorNameTooltip>{fullName}</AuthorNameTooltip>
              {!isAdvertisement && (
                <Link css={StaticAvatar} to={`/member/${memberSlug}/`}>
                  <AuthorProfileIcon>
                    {getInitials(firstName)}
                  </AuthorProfileIcon>
                </Link>
              )}
            </AuthorListItem>
          </AuthorList>
          {!isAdvertisement && createdDate}
          {timeToRead && !isAdvertisement && (
            <ReadingTime>{timeToRead} min read</ReadingTime>
          )}
        </PostCardMeta>
      </PostCardContent>
    </article>
  );
};
