export const colors = {
  blue: '#284B63',
  black: '#000000',
  darkgrey: '#15171A',
  green: '#073b2b',
  lightgrey: '#B4B8AB',
  midgrey: '#B4B8AB',
  offgrey: '#7e8897',
  red: '#98201A',
  whitegrey: '#e5eff5',
  white: '#ffffff',
  offwhite: '#F4F9E9',
};
