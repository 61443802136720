import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { colors } from 'styles';

export const PostFullFooterRightDiv = styled.div`
  flex-shrink: 0;
  margin-left: 20px;
`;

export const AuthorCardButton = css`
  display: block;
  padding: 9px 16px;
  border: ${colors.green} 1px solid;
  color: ${colors.green};
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 500;
  border-radius: 20px;
  transition: all ease 0.2s;

  :hover {
    border-color: ${colors.green};
    color: ${colors.white};
    text-decoration: none;
    background: ${colors.green};
  }
`;
