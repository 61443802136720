import { indoorTrackOrder, outdoorTrackOrder } from 'utils';

// Types
import { Record } from 'types';
interface Props {
  season: string;
  tableData: Record[];
}

export const orderBySeason = ({ tableData, season }: Props) => {
  // Sort by the proper order.
  const orderedEvents: any[] = [];
  const seasonMap = {
    'Indoor track': indoorTrackOrder,
    'Outdoor track': outdoorTrackOrder,
  };
  // @ts-ignore
  seasonMap[season].forEach((item: string) => {
    orderedEvents.push(
      tableData.filter(({ event }) => {
        if (event) return event.toString() === item;
        return null;
      })
    );
  });
  // This is an alternative way to use `.flat`.
  return [].concat(...orderedEvents);
};
