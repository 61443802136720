import styled from '@emotion/styled';
import { colors } from 'styles';

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  margin-top: 4rem;
`;

export const TabContent = styled.div`
  border-radius: 0 0 0.25rem 0.25rem;
  background: ${colors.white};
  flex: 1;
  padding: 4rem 0;
`;

export const TabContainer = styled.div`
  display: inline-flex;
`;

export const TabInput = styled.input`
  display: none;

  &:hover + label {
    background: #ddd;
    color: #666;
  }

  // Selected tab
  &:checked + label {
    background: ${colors.white};
    color: ${colors.green};
  }
`;
export const TabLabel = styled.label`
  background: ${colors.lightgrey};
  border-bottom: 1px solid ${colors.offgrey};
  color: ${colors.blue};
  border-radius: 0;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 1em;
  height: 2.5em;
  justify-content: center;
  line-height: 2.5em;
  padding: 0 1.5em;
  &:first-of-type {
    border-radius: 0.25em 0 0 0;
  }
  &:last-of-type {
    border-radius: 0 0.25em 0 0;
  }
`;
