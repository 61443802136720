import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from 'styles';

// Types
import { Props } from './ScrollButton';

export const BackToTopButton = styled.div<Props>`
  align-items: center;
  background-color: ${colors.blue};
  border-radius: 0.3125rem;
  bottom: 1.25rem;
  padding: 0 1.25rem;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
  color: ${colors.white};
  display: flex;
  justify-content: center;
  position: fixed;
  text-align: center;
  height: 5.5rem;
  animation-duration: 1.5s;
  cursor: pointer;
  z-index: 51;
  
  animation-name: ${({
    isScrollingDown,
    isScrollingUp,
    isAboveTopThreshold,
  }) => {
    if (isAboveTopThreshold) {
      return scrollingUpSlideIn;
    }
    if (isScrollingDown) {
      return scrollingUpSlideIn;
    }
    if (isScrollingUp) {
      return scrollingDownSlideOut;
    }
  }};
  right: ${({ isScrollingDown, isScrollingUp, isAboveTopThreshold }) => {
    if (isAboveTopThreshold) {
      return '-12rem';
    }
    if (isScrollingDown) {
      return '-12rem';
    }
    if (isScrollingUp) {
      return '0.625rem';
    }
  }};
}
`;

export const Wrapper = styled.div`
  min-height: calc(100vh - 225px);
  > h1 {
    color: ${colors.black};
  }
`;

/*****
 ** Animations
 *****/
const scrollingUpSlideIn = keyframes`
  from  {right: 0.625rem;}
  to {right: -12rem;}
`;

const scrollingDownSlideOut = keyframes`
  from {right: -12rem;}
  to {right: 0.625rem;}
`;
