import moment from "moment";
import { SEASONS } from "../client-constants";

/**
 * This function returns the string representation
 * of the ongoing season
 * @returns {string} currentSeason
 */
export const getCurrentSeason = () => {
  // Get date object for today
  const today = moment();

  // Setup the date objects for each season
  const outdoorTrack = moment().date(1).month("March");
  const crossCountry = moment().date(1).month("July");
  const indoorTrack = moment().date(20).month("November");

  // By default we set the current season to indoor track.
  // This catches the Jan - April dates
  let currentSeason = SEASONS.INDOOR_TRACK;

  // This catches the April - July dates
  if (today.isAfter(outdoorTrack)) {
    currentSeason = SEASONS.OUTDOOR_TRACK;
  }

  // This catches the July - December dates
  if (today.isAfter(crossCountry)) {
    currentSeason = SEASONS.CROSS_COUNTRY;
  }

  // This catches the December - Jan cases
  if (today.isAfter(indoorTrack)) {
    currentSeason = SEASONS.INDOOR_TRACK;
  }

  return currentSeason;
};
