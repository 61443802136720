import { useIdentityContext } from 'react-netlify-identity-gotrue';

export const useAuth = () => {
  const identity = useIdentityContext();
  const name = identity?.user?.user_metadata?.firstName || 'NoName';

  return {
    isLoggedIn: identity?.user?.id,
    logoutUser: identity.logout,
    name,
    ...identity,
  };
};
