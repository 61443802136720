import { graphql, useStaticQuery } from 'gatsby';

export const useLogo = () => {
  const { logo } = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "MSTCA.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return logo.childImageSharp.fixed;
};
