/**
 * Polyfill for CustomEvent for IE
 */


(() => {
  if (typeof window !== 'undefined') return;
  else if (typeof window !== 'undefined' && typeof window.CustomEvent === 'function') return false;
  const CustomEvent = (event, params = { bubbles: false, cancelable: false, detail: null }) => {
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  };
  CustomEvent.prototype = typeof window !== 'undefined' && window.Event.prototype;
  return typeof window !== 'undefined' ? window.CustomEvent = CustomEvent : null;
})();

/**
 * @function animationThrottle
 * @param {string} type
 * @param {string} name
 * @param {object} obj
 * @description Used for creating smooth transitions in React.
 */

export const animationThrottle = (type, name, obj = window) => {
  let isRunning = false;
  const func = () => {
    if (isRunning) return;

    // Continue
    isRunning = true;

    requestAnimationFrame(() => {
      obj.dispatchEvent(new CustomEvent(name));
      isRunning = false;
    });
  };

  obj.addEventListener(type, func);
};
