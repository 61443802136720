import styled from '@emotion/styled';
import { lighten } from 'polished';
import { Document } from 'react-pdf';
import { colors } from 'styles';

export const StyledDocument = styled(Document)`
  margin: 0 auto;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const PageButton = styled.button<{ disabled: boolean }>`
  font-size: 3rem;
  font-weight: 600;
  background: transparent;
  border: none;
  color: ${({ disabled }) =>
    disabled ? lighten(0.45, colors.darkgrey) : colors.blue};
`;
