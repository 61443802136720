import styled from '@emotion/styled';
import { lighten } from 'polished';
import { colors } from 'styles';

export const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  background: ${colors.red};
  position: sticky;
  top: 0;
  box-shadow: 0 2px 1.25rem ${lighten(0.5, colors.darkgrey)};
  margin-bottom: 1.25rem;
`;

export const StyledFilter = styled.div`
  flex-grow: 1;
  min-height: 6.25rem;
  background: ${colors.red};
  box-sizing: border-box;
  margin: 0.5rem;
  justify-content: space-between;
  padding: 0.25rem 2rem;
  text-align: center;
  h3 {
    margin-top: 10px;
    color: ${colors.white};
  }
    @media (max-width: 650px) {
    padding: 0.25rem 0;
  }
`;

export const Select = styled.select<{ disabled: boolean }>`
  max-width: 20rem;
  background: ${colors.white};
  background: ${({ disabled }) => disabled && colors.midgrey};
`;
