import React from 'react';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';

// Local Styles
import { Article, FullName, PostCardStyles, Grid } from './local-styles';

// Styles
import { MarginTop_sm } from 'styles';

// Hooks
import { useLogo } from 'hooks';

// Types
import { IMember } from 'types';
interface IProps {
  content: any;
  maxWidth?: number;
}

export const ProfileCards = (props: IProps) => {
  const { content, maxWidth } = props;
  const logoFallback = useLogo().src;

  return (
    <Grid maxWidth={maxWidth}>
      {content?.map(({ data }: { data: IMember }) => {
        const profilePicture =
          get(data, 'profile_picture[0].thumbnails.large.url') || logoFallback;
        const fullName = get(data, 'full_name', '') || '';
        const id = get(data, 'id', uniqueId()) || uniqueId();
        const bio = get(data, 'bio', '') || '';
        const email = get(data, 'email', '') || '';
        const organization = get(data, 'organization', '') || '';
        const twitter = get(data, 'twitter', '') || '';
        const specialties = get(data, 'specialties', []) || [];

        return (
          <Article css={[PostCardStyles]} key={id}>
            {profilePicture && <img alt={fullName} src={profilePicture} />}
            <hr />
            <FullName>
              {fullName}
              <br />
              <i>{!!specialties.length && specialties.join(', ')}</i>
              {organization && (
                <div css={MarginTop_sm}>
                  <i>&nbsp;{organization}</i>
                </div>
              )}
            </FullName>
            <hr />
            <p>{bio}</p>
            {email && <a href={`mailto: ${email}`}>Send Email</a>}
            {twitter ? (
              <a
                href={`https://www.twitter.com/${twitter}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {`@${twitter}`}
              </a>
            ) : null}
          </Article>
        );
      })}
    </Grid>
  );
};
