import { ToggleSwitch } from 'components/ToggleSwitch';
import snakeCase from 'lodash/snakeCase';
import toLower from 'lodash/toLower';
import React, { ChangeEvent } from 'react';

// Types
interface Props {
  toggles: Array<{
    title: string;
    gqlKey: string;
    hasAllOption?: boolean;
    defaultChecked?: boolean;
  }>;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
}

export const ToggleArray = (props: Props) => {
  const { toggles, onChange } = props;

  return (
    <>
      {toggles.map(({ defaultChecked, title, gqlKey }) => {
        const value = toLower(title);

        // Ensure it's snake case.
        const snake = snakeCase(gqlKey);

        return (
          <ToggleSwitch
            defaultChecked={defaultChecked}
            key={value}
            name={snake}
            onChange={onChange}
            title={title}
          />
        );
      })}
    </>
  );
};
