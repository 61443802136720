import { graphql, useStaticQuery } from 'gatsby';

export const useOutdoorAllState = () => {
  const { logo } = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "outdoor-track/outdoor-allstate.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return logo.childImageSharp.fluid;
};
