import React, { ReactElement, useState } from 'react';

// Styles
import {
  TabContainer,
  TabsContainer,
  TabContent,
  TabInput,
  TabLabel,
} from './local-styles';

// Types
interface IProps {
  defaultIndex?: number;
  tabTitles: string[];
  tabContents: ReactElement[];
}

export const Tabs = (props: IProps): ReactElement => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { defaultIndex = 0, tabTitles, tabContents } = props;

  return (
    <TabsContainer>
      {/* Tab Titles */}
      <TabContainer>
        {tabTitles.map((tab: string, index: number) => (
          <>
            <TabInput
              defaultChecked={defaultIndex === index}
              disabled={index === activeIndex}
              id={`tab-${index}`}
              name="tab-group"
              type="radio"
              onClick={() => setActiveIndex(index)}
            />
            <TabLabel htmlFor={`tab-${index}`}>{tab}</TabLabel>
          </>
        ))}
      </TabContainer>
      {/*  Tab Content */}
      <TabContent>
        <>
          {tabContents.map((content: ReactElement, activeTabIdx: number) => (
            <>{activeTabIdx === activeIndex && <>{content}</>}</>
          ))}
        </>
      </TabContent>
    </TabsContainer>
  );
};
