import { Post } from '../components/PostCard/types';
import get from 'lodash/get';
import { Posts } from '../pages';
import { Advertisement } from '../types';

export const usePostsWithAdvertisements = (posts: Post[]) => {
  // Get only the advertisements.
  const advertisements = posts
    .map((post: Post): Post | void => {
      const hasAd: boolean = get(post, 'data.advertisements[0]');

      if (hasAd) return post;
    })
    .filter(Boolean);

  // Build an array with key of "post_position", value is ad.
  const adIndices = advertisements.map(
    (ad: Advertisement): { [key: number]: Advertisement } => {
      const position: number = get(
        ad,
        'data.advertisements[0].data.post_position'
      );

      return { [position]: ad };
    }
  );

  // Build a pure array of posts without ads.
  const finalPostsArray: Posts[] = [];
  posts.map((post: Post): Post[] => {
    if (!get(post, 'data.advertisements[0]')) finalPostsArray.push(post);
  });

  // Create an array of the desired placement of these ads.
  const indices: number[] = adIndices
    .map((ad: Advertisement) => Object.keys(ad))
    .flat();

  // Map over adIndices and splice that object into the posts array.
  adIndices.map((ad: { [key: number]: Posts }, idx: number): void => {
    const adIndex: number = indices[idx];
    const actualPlacement: number = adIndex - 1;

    finalPostsArray.splice(actualPlacement, 0, ad[adIndex]);
  });

  return { postsWithAdvertisements: finalPostsArray };
};
