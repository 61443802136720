import React, { PureComponent } from 'react';

// Components
import { ScrollButton } from './ScrollButton';

// Local Styles
import { Wrapper } from './local-styles';

// Types
interface Props {
  topThreshold?: number;
}

const isWindowDefined = typeof window !== 'undefined';

export class WithScrollToTopButton extends PureComponent<Props> {
  public state = {
    isScrollingDown: false,
    isScrollingUp: false,
    isAboveTopThreshold: true,
  };

  private prev: number;
  constructor(props: Readonly<Props>) {
    super(props);
    this.prev = (isWindowDefined && window.scrollY) || 0;
  }
  public componentDidMount() {
    this.prev = window.scrollY;
    isWindowDefined && window.addEventListener('scroll', this.handleScroll);
  }

  public componentWillUnmount() {
    return (
      isWindowDefined && window.removeEventListener('scroll', this.handleScroll)
    );
  }

  public handleScroll = (e: { currentTarget: any }) => {
    const { topThreshold = 300 } = this.props;
    const window = e.currentTarget;
    const scrollPosition = window.scrollY;
    // If the scroll position is above a certain point, we won't show the button.
    const isAboveTopThreshold = scrollPosition < topThreshold;

    if (this.prev > scrollPosition) {
      this.setState({
        isScrollingDown: false,
        isScrollingUp: true,
        scrollPosition,
        isAboveTopThreshold,
      });
    } else if (this.prev < scrollPosition) {
      this.setState({
        isScrollingDown: true,
        isScrollingUp: false,
        scrollPosition,
        isAboveTopThreshold,
      });
    }
    this.prev = window.scrollY;
  };

  public render() {
    const { children, ...rest } = this.props;
    const { isScrollingDown, isScrollingUp, isAboveTopThreshold } = this.state;

    return (
      <Wrapper>
        {children}
        <ScrollButton
          isScrollingDown={isScrollingDown}
          isScrollingUp={isScrollingUp}
          isAboveTopThreshold={isAboveTopThreshold}
          {...rest}
        />
      </Wrapper>
    );
  }
}
