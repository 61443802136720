import { FluidObject } from 'gatsby-image';
import get from 'lodash/get';

interface FluidImages {
  childImageSharp: {
    fluid: FluidObject;
  };
}

/*
 * @function getAttachments
 * @param {Data} data - data from graphQL Airtable
 * @returns {FluidImages[]}
 * @description - helper function to return an array of fluid objects.
 * */

export const getAttachments = (data: any): FluidImages[] =>
  get(data, 'attachments.localFiles', []) || [];
