import React, { ReactNode } from 'react';

// Local Styles
import { StyledWrapper } from './local-styles';

// Types
interface Props {
  children: ReactNode;
  className?: string;
}

export const Wrapper = ({ children, className }: Props) => (
  <StyledWrapper className={className}>{children}</StyledWrapper>
);
