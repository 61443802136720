import { graphql, useStaticQuery } from 'gatsby';

export const useXCStatewide = () => {
  const { logo } = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "cross-country/statewide.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return logo.childImageSharp.fluid;
};
