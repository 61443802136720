import Markdown from 'markdown-to-jsx';
import React from 'react';

// Styles
import { PostFullContent } from 'styles';

// Types
export interface PostContentProps {
  markdown: any;
}

export const PostContent = ({ markdown }: PostContentProps) => {
  return (
    <PostFullContent className="post-full-content">
      <Markdown>{markdown}</Markdown>
    </PostFullContent>
  );
};
