import { graphql, useStaticQuery } from 'gatsby';

export const useOutdoorAllTimeMeets = () => {
  const { outdoorTrackExtra } = useStaticQuery(graphql`
    {
      outdoorTrackExtra: allAirtable(
        filter: {
          table: { eq: "Outdoor Track And Field Records" }
          data: { include_all_time: { eq: true } }
        }
      ) {
        nodes {
          data {
            event
            record_holder_or_holders
            record_holder_image {
              thumbnails {
                large {
                  url
                }
              }
            }
            year
            team
            meet_name
            record
            result
            gender
            meet_location
          }
        }
      }
    }
  `);
  return outdoorTrackExtra.nodes || [];
};
