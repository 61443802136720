import { graphql, useStaticQuery } from 'gatsby';

export const useNavQuery = () => {
  const { coachingVacancyCount } = useStaticQuery(
    graphql`
      {
        coachingVacancyCount: allAirtable(
          filter: {
            table: { eq: "Posts" }
            data: { tags: { eq: "Coaching Vacancies" } }
          }
        ) {
          totalCount
        }
      }
    `
  );

  return coachingVacancyCount;
};
