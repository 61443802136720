import { css } from '@emotion/core';

/****************
 * MARGIN
 *****************/
// All sides
export const Margin_sm = css`
  margin: 0.5rem;
`;
export const Margin_md = css`
  margin: 1rem;
`;
export const Margin_lg = css`
  margin: 1.5rem;
`;
export const Margin_xl = css`
  margin: 2rem;
`;
export const Margin_xxl = css`
  margin: 2.5rem;
`;
export const Margin_xxxl = css`
  margin: 3rem;
`;

// Left side
export const MarginLeft_sm = css`
  margin-left: 0.5rem;
`;
export const MarginLeft_md = css`
  margin-left: 1rem;
`;
export const MarginLeft_lg = css`
  margin-left: 1.5rem;
`;
export const MarginLeft_xl = css`
  margin-left: 2rem;
`;
export const MarginLeft_xxl = css`
  margin-left: 2.5rem;
`;
export const MarginLeft_xxxl = css`
  margin-left: 3rem;
`;

// Right side
export const MarginRight_sm = css`
  margin-left: 0.5rem;
`;
export const MarginRight_md = css`
  margin-left: 1rem;
`;
export const MarginRight_lg = css`
  margin-left: 1.5rem;
`;
export const MarginRight_xl = css`
  margin-left: 2rem;
`;
export const MarginRight_xxl = css`
  margin-left: 2.5rem;
`;
export const MarginRight_xxxl = css`
  margin-left: 3rem;
`;

// Top side
export const MarginTop_sm = css`
  margin-top: 0.5rem;
`;
export const MarginTop_md = css`
  margin-top: 1rem;
`;
export const MarginTop_lg = css`
  margin-top: 1.5rem;
`;
export const MarginTop_xl = css`
  margin-top: 2rem;
`;
export const MarginTop_xxl = css`
  margin-top: 2.5rem;
`;
export const MarginTop_xxxl = css`
  margin-top: 3rem;
`;

// Bottom side
export const MarginBottom_sm = css`
  margin-bottom: 0.5rem;
`;
export const MarginBottom_md = css`
  margin-bottom: 1rem;
`;
export const MarginBottom_lg = css`
  margin-bottom: 1.5rem;
`;
export const MarginBottom_xl = css`
  margin-bottom: 2rem;
`;
export const MarginBottom_xxl = css`
  margin-bottom: 2.5rem;
`;
export const MarginBottom_xxxl = css`
  margin-bottom: 3rem;
`;
