import { Link } from 'gatsby';
import React from 'react';

// Styles
import { AuthorProfileImage } from 'styles';

// Types
interface AuthorAvatarProps {
  memberSlug: string;
  fullName: string;
  profilePicture: string;
}

export const AuthorAvatar = ({
  fullName,
  memberSlug,
  profilePicture,
}: AuthorAvatarProps) => (
  <Link to={`/member/${memberSlug}/`}>
    {profilePicture && (
      <img alt={fullName} css={AuthorProfileImage} src={profilePicture} />
    )}
  </Link>
);
