import FluidObject from "gatsby-image";
import { Data } from "templates";
import { Post } from "components/PostCard/types";

export interface Attachment {
  id: string;
  localFiles: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
}

export interface ProfilePicture {
  thumbnails: {
    large: {
      url: string;
    };
    full: {
      url: string;
    };
  };
}

export interface Advertisement {
  attachments: Attachment[];
  external_url: string;
  post_position: number;
  posts: Post[];
}

export interface Sections {
  data: Sponsor[];
}

export interface Section {
  id: string;
  data: {
    markdown_content: string;
    section_title: string;
    attachments: Attachment[];
  };
}

export interface Sponsor {
  sponsor_name: string;
  sponsor_url: string;
  attachments: Attachment[];
}

export interface Sponsors {
  data: Sponsor[];
}

export interface Record {
  champion: string;
  division: BoysDivision | GirlsDivision;
  event: Event;
  gender: Gender;
  individual_champion: string;
  meet_name: MeetName;
  points: number;
  result: string;
  season: Season;
  team: string;
  team_champion: string;
  venue: string;
  year: string;
}

export interface RecordHolder {
  event: string;
  gender: string;
  meet_location: string;
  meet_name: string;
  record: string;
  record_holder_image: ProfilePicture;
  record_holder_or_holders: string[];
  result: string;
  team: string;
  year: string;
}

export interface CurrentMember {
  first_name: string;
  last_name: string;
  full_name: string;
  member_slug: number;
  organization: string;
  totalCount: number;
}

export interface Meet {
  dayNumber: string;
  is_active: boolean;
  meet_date: string;
  meet_name: string;
  meet_slug: string;
  month: string;
  result_link: string;
  resultPDFs: IPDF[];
  season: Season;
  venue: string;
  weekDay: string;
  year: string;
}

export interface MeetingMinute {
  meeting_minutes_id: string;
  markdown_content: string;
  created_date: string;
  link: string;
}

export interface IMember {
  bio?: string;
  email?: string;
  full_name: string;
  member_slug: string;
  organization: string;
  profile_picture?: ProfilePicture[];
  specialties?: string[];
}
export interface IMemberRoles {
  members: IMember[];
  role: keyof MemberRole;
}

export interface OrganizationSummary {
  balance: number;
  name: string[];
}

export interface MemberRole {
  mstcaCabinet: Data[];
  executiveBoardMembers: Data[];
  executiveBoardOfficers: Data[];
  financeCommittee: Data[];
  indoorTrackCommittee: Data[];
  outdoorTrackCommittee: Data[];
  webmasters: Data[];
  xCountryCommittee: Data[];
}

export interface HOF {
  bio: string;
  first_name: string;
  full_name: string;
  gender: string;
  hof_id: number;
  induction_year: string;
  last_name: string;
  attachments: Attachment[];
  school: string;
}

export interface AthleteHOF extends HOF {
  events: string[];
  class_year?: string;
}

export interface IPDF {
  id: string;
  thumbnails?: {
    small: {
      url: string;
    };
  };
  url: string;
}

export interface Form {
  id: string;
  attachments: Attachment[];
  form_name: string;
  link: string;
}

export interface Forms {
  forms: Form[];
}

export interface Clinician {
  full_name: string;
  profile_picture?: ProfilePicture[];
  bio?: string;
}

export interface CampOrClinic {
  title: string;
  markdown_content: string;
  form_url: string;
  attachments?: {
    localFiles: [];
  };
  sponsors: Sponsor[];
  clinicians: Clinician[];
}

export interface IToggle {
  defaultChecked?: boolean;
  gqlKey: string;
  hasAllOption?: boolean;
  title: string;
}

export interface IFilter {
  isMostRecent?: boolean;
  gqlKey: string;
  hasAllOption?: boolean;
  title: string;
  value?: string;
}

export type Season = "Cross country" | "Indoor track" | "Outdoor track";
export type BoysDivision = "B-D1" | "B-D2" | "B-D3" | "B-D4" | "B-D5" | "B-D6";
export type GirlsDivision = "G-D1" | "G-D2" | "G-D3" | "G-D4" | "G-D5" | "G-D6";
export type Gender = "Boys" | "Girls" | "Male" | "Female";
export type MeetName =
  | "All State"
  | "Central Mass"
  | "Eastern Mass"
  | "Statewide"
  | "Western Mass";
export type Event =
  | "100M"
  | "200M"
  | "400M/440Y"
  | "800M"
  | "1500M"
  | "1 Mile"
  | "2000M"
  | "2000M Steeplechase"
  | "3000M"
  | "3000M Steeplechase"
  | "2 Mile"
  | "3 Mile"
  | "5000M"
  | "6 Mile"
  | "10000M"
  | "110M Hurdles"
  | "300M Low Hurdles"
  | "400M Hurdles"
  | "4X100M";

export type Filters =
  | HOF
  | Record
  | CurrentMember
  | MeetingMinute
  | OrganizationSummary
  | Meet
  | Forms;

export enum SORT_BY {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SEASON {
  CROSS_COUNTRY = "Cross country",
  INDOOR_TRACK = "Indoor track",
  OUTDOOR_TRACK = "Outdoor track",
}
