import React, { ReactElement, SyntheticEvent } from 'react';
import { Modal, ModalTransition, useModal } from 'react-simple-hook-modal';
import classNames from 'classnames';

// Styles
import { DropdownContent } from '../local-styles';
import 'react-simple-hook-modal/dist/styles.css';

// Hooks
import { useAuth } from 'hooks';

// Forms
import { Login, Signup } from 'forms';
import { Tabs } from '../../Tabs';

export const AuthMenu = (): ReactElement => {
  const { isLoggedIn, name, logoutUser } = useAuth();
  const { isModalOpen, closeModal, openModal } = useModal();

  const doNothing = (e: SyntheticEvent): void => e.preventDefault();

  return (
    <>
      {isLoggedIn ? (
        <li className="dropdown">
          <button
            className={classNames(isLoggedIn, 'welcome-title')}
            onClick={doNothing}
          >
            {name}
          </button>
          <DropdownContent>
            <button onClick={logoutUser}>Logout</button>
          </DropdownContent>
        </li>
      ) : (
        <li className="dropdown">
          <button onClick={openModal}>Login</button>
        </li>
      )}
      <Modal
        id="_mstca-simple-mdl-wrapper"
        isOpen={isModalOpen}
        modalClassName="_mstca-simple-mdl"
        onBackdropClick={closeModal}
        transition={ModalTransition.BOTTOM_UP}
      >
        <button className="_mscta-simple-mdl-close-btn" onClick={closeModal}>
          X
        </button>
        <Tabs
          tabTitles={['Log in', 'Sign up']}
          tabContents={[<Login />, <Signup />]}
        />
      </Modal>
    </>
  );
};
