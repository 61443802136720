import React from 'react';
import Helmet from 'react-helmet';

// Hooks
import { useSiteMetadata } from 'hooks';

// Types
interface Props {
  lang?: string;
  meta?: any;
  title: string;
}

export const SEO = ({ lang, meta, title }: Props) => {
  const { description, author } = useSiteMetadata();

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`MTSCA.org | ${title}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `author`,
          content: author,
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  title: '',
};
