import get from 'lodash/get';

/*
 * @function getSectionTitle
 * @param {Data} data - data from graphQL Airtable
 * @returns {string}
 * @description - helper function to return the section title.
 * */

export const getSectionTitle = (data: any): string =>
  get(data, 'section_title', '') || '';
