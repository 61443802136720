import { Meet } from './TrackSeasonCards';

export const MIAA_DIVISIONS = [
  'All Time',
  // Outdoor
  'MIAA Outdoor All State Meet',
  'MIAA EMass Outdoor Division 1 Championships',
  'MIAA EMass Outdoor Division 2 Championships',
  'MIAA EMass Outdoor Division 3 Championships',
  'MIAA EMass Outdoor Division 4 Championships',
  'MIAA WMass/CMass Outdoor Division 1 Championships',
  'MIAA WMass/CMass Outdoor Division 2 Championships',
  // Indoor
  'MIAA Indoor All State Meet',
  'MIAA Indoor Division 1 Championships',
  'MIAA Indoor Division 2 Championships',
  'MIAA Indoor Division 3 Championships',
  'MIAA Indoor Division 4 Championships',
  'MIAA Indoor Division 5 Championships',
];

export const orderByMeet = (trackMeets: Meet[]) => {
  // Sort by the proper order.
  const orderedEvents: any[] = [];
  // @ts-ignore
  MIAA_DIVISIONS.forEach((item: string) => {
    orderedEvents.push(
      trackMeets.filter(({ meetName }) => meetName.toString() === item)
    );
  });
  // This is an alternative way to use `.flat`.
  return [].concat(...orderedEvents);
};
