import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import Sticky from "react-sticky-el";
import get from "lodash/get";

// Components
import { EmptyResults } from "./EmptyResults";
import { FiltersArray } from "./FiltersArray";
import { ToggleArray } from "./ToggleArray";

// Local styles
import { FilterWrapper } from "components/Filter/styles";

// Hooks
// @ts-ignore
import { useIsAboveTablet } from "components/MediaQuery";

// Misc.
import { getCurrentSeason } from "utils";
import { filterBy } from "../Filter/filterBy";
import { formatPlural } from "./utils/formatPlurual";

// Types
import { Filters, IFilter, IToggle, RecordHolder, SEASON, SORT_BY } from "types";

interface IProps {
  data: {
    nodes: RecordHolder[];
  }[];
  filters: IFilter[];
  path?: string;
  isHidingFilters?: boolean;
  toggles?: IToggle[];
  sortBy?: SORT_BY;

  render(arg0: any): void;
}

interface IFilters {
  gqlKey: string;
  value: string;
  hasAllOption?: boolean;
}

export const FilterBar = (props: IProps) => {
  const {
    data,
    filters,
    isHidingFilters,
    path,
    render,
    sortBy,
    toggles = []
  } = props;
  const [filteredData, setFilter] = useState<{}>({});

  // Get raw shape => [{data: {...}}, {data: {...}}];
  const rawGQLData: [] = get(data, "nodes", []) || [];

  const seasonFilter = useCallback(
    (filterKey: IFilters) => {
      const { gqlKey } = filterKey;
      const data = [
        ...new Set(rawGQLData.map(({ data }: any) => data[gqlKey]).sort())
      ];

      data.unshift(getCurrentSeason());

      return [...new Set(data)];
    },
    [rawGQLData]
  );

  const uniqueOptions = useCallback(
    (filterKey: IFilter) => {
      const { gqlKey, value, hasAllOption } = filterKey;
      const allValues = formatPlural(value);
      const data = [
        ...new Set(rawGQLData.map(({ data }: any) => data[gqlKey]).sort())
      ];

      // Special order for 'season'. We need to show the current season at the top.
      if (value === "season") {
        data.unshift(getCurrentSeason());
        return [...new Set(data)];
      }

      // We can explicitly tell the filter we don't want to include `All xxx`.
      if (hasAllOption === false) {
        return data;
      }

      // Otherwise we will add `All xxx` by default.
      data.unshift(allValues);
      return data;
    },
    [rawGQLData]
  );

  useEffect(() => {
    // We need to find any filters that don't explicitly
    // have `hasAllOption === false` or `isMostRecent` so that the initial render is proper.
    const exceptions = filters.filter(
      ({ hasAllOption, isMostRecent }: IFilter): boolean =>
        hasAllOption === false || !!isMostRecent
    );

    // Set initialFiltersState with these to the filter option.
    // Example => { season: 'Cross country', ... }; NOTE: Special case for season.
    const initialFiltersState = exceptions.map((exception: IFilter) => {
      const { gqlKey, isMostRecent } = exception;
      const firstException: IFilter = exceptions[0];

      const currentSeason: SEASON = seasonFilter(firstException)[0];
      const getCurrentYear = (): string => {
        if (currentSeason === SEASON.INDOOR_TRACK) {
          if (new Date().getMonth() >= 9) {
            return new Date().getFullYear().toString();
          } else {
            return (new Date().getFullYear() - 1).toString();
          }
        }
        return new Date().getFullYear().toString();
      };

      return {
        [gqlKey]: uniqueOptions(firstException).reverse()[0],
        ...(gqlKey === "season" && {
          season: currentSeason
        }),
        ...(gqlKey === "year" && isMostRecent && { year: getCurrentYear() })
      };
    });

    // Set initialTogglesState for each key, make them false.
    const initialTogglesState =
      toggles?.map((toggle) => ({
        [toggle.gqlKey]: toggle.defaultChecked || false
      }));

    // Set the actual initial state.
    setFilter((state) => ({
      ...state,
      ...Object.assign({}, ...initialFiltersState),
      ...Object.assign({}, ...initialTogglesState)
    }));
  }, [seasonFilter, filters, uniqueOptions]);

  // Transform to shape => [{...}, {...}];
  const gqlArray: Filters[] = rawGQLData.map(
    ({ data }: { data: Filters }): Filters => data
  );

  useMemo(() => {
    filterBy(gqlArray, filteredData);
  }, [gqlArray, filteredData]);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const key: string = e.target.name.toString();
    const value: string | boolean =
      e.target.value === "checkbox"
        ? e.target.checked
        : e.target.value.toString();

    setFilter({
      ...filteredData,
      [key]: value
    });
  };

  // Filtered data to show in the table.
  const tableData =
    sortBy === SORT_BY.DESC
      ? filterBy(gqlArray, filteredData).reverse()
      : filterBy(gqlArray, filteredData);
  const { isAboveTablet } = useIsAboveTablet();
  const isHallOfFame = !!path && path.includes("hall-of-fame");
  const isDisabled = !isAboveTablet || isHallOfFame;
  const trueFilters = Object.keys(filteredData || {}).filter(
    (entry: string): boolean => filteredData[entry] === true
  );
  const isSelectFiltersDisabled: boolean = trueFilters.length > 0;

  return (
    <>
      <section>
        {!isHidingFilters && (
          <Sticky disabled={isDisabled} stickyStyle={{ zIndex: 1 }}>
            <FilterWrapper>
              <FiltersArray
                filters={filters}
                isSelectFiltersDisabled={isSelectFiltersDisabled}
                onChange={onChange}
                uniqueOptions={uniqueOptions}
              />
              <ToggleArray toggles={toggles} onChange={onChange} />
            </FilterWrapper>
          </Sticky>
        )}
        {tableData.length <= 0 ? <EmptyResults /> : render(tableData)}
      </section>
    </>
  );
};
