import React, { SyntheticEvent, useState } from 'react';
import { Link } from 'gatsby';

// Components
import { SiteNavLogo } from './SiteNavLogo';
import { AuthMenu } from './AuthMenu';

// Local Styles
import {
  DropdownContent,
  HomeNavRaise,
  NavStyles,
  SiteNavLeft,
  SiteNavRight,
  SiteNavStyles,
} from './local-styles';

// Hooks
import { useNavQuery } from 'hooks';

// Styles
import { colors } from 'styles';

// Misc.
import { ExternalLink } from '../icons';

// Types
interface Props {
  isHome: boolean;
}

interface State {
  isModalOpen: boolean;
}

export const SiteNav = (props: Props) => {
  useState<State>({ isModalOpen: false });

  const { isHome } = props;
  // const subscribeRef = useRef<SubscribeOverlay>(null);
  // const openModal = () => {
  //   if (subscribeRef.current) {
  //     subscribeRef.current.open();
  //   }
  // };

  const doNothing = (e: SyntheticEvent) => e.preventDefault();

  // Find the total number of coaching vacancies.
  const { totalCount } = useNavQuery();

  return (
    <nav css={[isHome && HomeNavRaise, SiteNavStyles]}>
      <SiteNavLeft>
        {!isHome && <SiteNavLogo />}
        <ul css={NavStyles}>
          <li className="dropdown">
            <button onClick={doNothing}>Coaches Corner</button>
            <DropdownContent>
              <Link to="/about">About</Link>
              <Link to="/our-team">Our Team</Link>
              <Link to="/bylaws">Bylaws</Link>
              <Link to="/join-us">Join Us</Link>
              <Link to="/current-members">Current Members</Link>
              <Link to="/meeting-minutes">Meeting Minutes</Link>
              <Link to="/forms">MSTCA Forms</Link>
              {totalCount && totalCount > 0 && (
                <Link to="/tags/coaching-vacancies">Coaching Vacancies</Link>
              )}
              <a
                href="https://forms.gle/s39FPrvC6d7MCYst5"
                rel="noopener noreferrer"
                target="_blank"
              >
                Contact Us
                <ExternalLink
                  fill={colors.white}
                  style={{ margin: '-4px 5px 0' }}
                />
              </a>
            </DropdownContent>
          </li>
          <li className="dropdown">
            <Link to="/calendar">Calendar/Results</Link>
          </li>
          <li className="dropdown">
            <button onClick={doNothing}>Records/champions</button>
            <DropdownContent>
              <Link to="/champions/cross-country">Cross country champions</Link>
              <Link to="/champions/outdoor-track">Outdoor track champions</Link>
              <Link to="/champions/indoor-track">Indoor track champions</Link>
              <button onClick={doNothing} />
              <Link to="/records/indoor-track">Indoor track records</Link>
              <Link to="/records/outdoor-track">Outdoor track records</Link>
              <button onClick={doNothing} />
              <Link to="/hall-of-fame/athletes">Athletes Hall of Fame</Link>
              <Link to="/hall-of-fame/coaches">Coaches Hall of Fame</Link>
            </DropdownContent>
          </li>
          <li className="dropdown">
            <button onClick={doNothing}>Scholarships/Clinics</button>
            <DropdownContent>
              <Link to="/campership/robert-mcintyre">
                Robert McIntyre Campership
              </Link>
              <Link to="/scholarship/mcintyre-polizzotto">
                Mcintyre/Polizzotto Scholarship
              </Link>
              <Link to="/camps-and-clinics/new-england-track-field-clinic">
                New England Track & Field Clinic
              </Link>
              <Link to="/camps-and-clinics/do-it-clinic">"Do it clinic"</Link>
            </DropdownContent>
          </li>
          <li className="dropdown">
            <button onClick={doNothing}>Archives</button>
            <DropdownContent>
              <a
                href="https://www.mstca.net"
                rel="noopener noreferrer"
                target="_blank"
              >
                Legacy MSTCA
                <ExternalLink
                  fill={colors.white}
                  style={{ margin: '-4px 5px 0' }}
                />
              </a>
              <Link to="/posts">Article Archives</Link>
            </DropdownContent>
          </li>
          <li className="dropdown">
            <a
              href="https://therunhouse.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Shop now
              <ExternalLink
                fill={colors.white}
                style={{ margin: '-4px 5px 0' }}
              />
            </a>
          </li>
        </ul>
      </SiteNavLeft>
      <SiteNavRight>
        {/*{config.showSubscribe && (*/}
        {/*  <SubscribeButton onClick={openModal}>Subscribe</SubscribeButton>*/}
        {/*)}*/}
        {/*{config.showSubscribe && <SubscribeOverlay ref={subscribeRef} />}*/}
        <ul css={NavStyles}>
          <AuthMenu />
        </ul>
      </SiteNavRight>
    </nav>
  );
};
