import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from 'styles';

export const Grid = styled.div<{ maxWidth?: number }>`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 80%;
  justify-content: center;

  @media (min-width: 700px) {
    grid-template-columns: ${({ maxWidth }) =>
      `repeat(auto-fill, ${maxWidth || 30}%)`};
  }
`;

export const Article = styled.article`
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  grid-column: span 1;
  margin: 10px auto;
  position: relative;
  text-align: center;
  > img {
    display: block;
    max-height: 50%;
  }
  a {
    color: ${colors.white};
    font-size: 1.5rem;
    border: 1px solid;
    display: inline;
    padding: 0.75rem 0;
  }
`;

export const FullName = styled.h2`
  font-weight: 600;
  margin: 0;
  > i {
    font-weight: 400;
    font-size: 1.5rem;
  }
`;

export const PostCardStyles = css`
  border-radius: 5px;
  box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px,
    rgba(39, 44, 49, 0.03) 1px 3px 8px;
  padding: 3.25rem;
  background: ${colors.green};
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;
