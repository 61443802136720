import React, { ReactElement } from 'react';
import AthleticLiveLogo from 'images/athletic-live-logo.png';

export const getResultLogo = ({
  url,
}: {
  url?: string;
}): ReactElement | undefined => {
  // If we have a URL, and it's live.mstca.org then use the Athletic Live logo.
  if (url?.includes('live.mstca.org'))
    return <img src={AthleticLiveLogo} alt="athletic live" height={20} />;

  // If we have a URL, and it's not athletic.net then use a trophy.
  if (!!url) {
    return (
      <span role="img" aria-label="trophy">
        🏆
      </span>
    );
  }
};
