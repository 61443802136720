import React from 'react';

// Local Styles
import { Wrapper } from './local-styles';

export const EmptyResults = () => (
  <Wrapper>
    <h3>No data right now. Please check back at a later time.</h3>
  </Wrapper>
);
