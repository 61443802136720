/*
 * For some reason Gatsby doesn't like having constants.js file.
 * This is a workaround.
 * */

export const MEMBER_ROLES = {
  EXECUTIVE_BOARD_MEMBERS: 'Executive Board Members',
  EXECUTIVE_BOARD_OFFICERS: 'Executive Board Officers',
  FINANCE_COMMITTEE: 'Finance Committee',
  INDOOR_TRACK_COMMITTEE: 'Indoor Track Committee',
  OUTDOOR_TRACK_COMMITTEE: 'Outdoor Track Committee',
  WEBMASTER: 'Webmaster',
  X_COUNTRY_COMMITTEE: 'X-Country Committee',
};

export const SEASONS = {
  CROSS_COUNTRY: 'Cross country',
  INDOOR_TRACK: 'Indoor track',
  OUTDOOR_TRACK: 'Outdoor track',
};

export const seasonValues = Object.values(SEASONS);
