export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  facebook?: string;
  /**
   * full url, no username
   */
  twitter?: string;
  /**
   * full url, no username
   */
  instagram?: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;
   /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
   /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
  /**
   * External link to sign up
   */
  joinUs: string;
}

const config: WebsiteConfig = {
  coverImage: 'img/blog-cover.jpg',
  description: 'Massachusetts State Track Coaches Association',
  facebook: 'https://www.facebook.com/groups/154532424151/',
  footer: 'is written by @bursteways',
  googleSiteVerification: 'GoogleCode',
  instagram: 'https://www.instagram.com/mass_state_track/',
  joinUs: 'https://mass-state-track-coaches-association.square.site/product/2019-2020-mstca-membership/9?cp=true&sbp=false',
  lang: 'en',
  logo: 'img/ghost-logo.png',
  mailchimpAction: 'https://twitter.us19.list-manage.com/subscribe/post?u=a89b6987ac248c81b0b7f3a0f&amp;id=7d777b7d75',
  mailchimpEmailFieldName: 'MERGE0',
  mailchimpName: 'b_a89b6987ac248c81b0b7f3a0f_7d777b7d75',
  showSubscribe: true,
  siteUrl: 'https://mscta.org',
  title: 'MSTCA',
  twitter: 'https://twitter.com/MSTCA1',
};

export default config;
