import React from 'react';
import config from '../../website-config';

// Components
// @ts-ignore
import { Phone, TabletUp } from '../MediaQuery';
import { SiteNav } from '../SiteNav';
import { BurgerMenu } from '../SiteNav/BurgerMenu';

// Local Styles
import { SiteDescription } from './local-styles';

// Styles
import {
  inner,
  outer,
  SiteHeader,
  SiteHeaderContent,
  SiteTitle,
  SocialLink,
} from 'styles';
import { Facebook, Instagram, Twitter } from '../icons';
import { SocialLinks } from '../SiteNav/local-styles';

// Types
interface Props {
  logo: {
    src: string;
  };
  hero: {
    src: string;
  };
}

export const Header = ({ logo, hero }: Props) => (
  <header
    css={[outer, SiteHeader]}
    style={{
      backgroundImage: `url('${hero.src}')`,
    }}
  >
    <div css={inner}>
      <SiteHeaderContent>
        <SiteTitle>
          {logo && (
            <img
              style={{ maxHeight: '130px', marginBottom: '10px' }}
              src={logo.src}
              alt={config.title}
            />
          )}
        </SiteTitle>
        <SiteDescription>{config.description}</SiteDescription>
        <SocialLinks>
          {config.facebook && (
            <a
              css={SocialLink}
              href={config.facebook}
              target="_blank"
              title="Facebook"
              rel="noopener noreferrer"
            >
              <Facebook />
            </a>
          )}
          {config.twitter && (
            <a
              css={SocialLink}
              href={config.twitter}
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter />
            </a>
          )}
          {config.twitter && (
            <a
              css={SocialLink}
              href={config.instagram}
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </a>
          )}
        </SocialLinks>
      </SiteHeaderContent>
      <Phone>
        <BurgerMenu />
      </Phone>
      <TabletUp>
        <SiteNav isHome={true} />
      </TabletUp>
    </div>
  </header>
);
