import styled from '@emotion/styled';

export const PostFullFoot = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 3vw 9rem;
  @media (max-width: 650px) {
    padding: 0;
  }
`;
