/*
 * Layout components are for sections of your site that
 * you want to share across multiple pages.
 * */

import React, { ReactNode } from 'react';

// Components
import { Footer, SiteNav, WithScrollToTopButton, Wrapper } from 'components';
// @ts-ignore
import { Phone, TabletUp } from 'components/MediaQuery';
import { BurgerMenu } from 'components/SiteNav/BurgerMenu';

// Styles
import { inner, innerFull, outer, PageTemplate, SiteHeader } from 'styles';

// Types
interface Props {
  children: ReactNode;
  full?: boolean;
}

export const PageLayout = (props: Props) => {
  const { children, full } = props;

  return (
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <Phone>
          <BurgerMenu />
        </Phone>
        <TabletUp>
          <SiteNav isHome={false} />
        </TabletUp>
      </header>
      <main css={[full ? innerFull : inner]}>
        <WithScrollToTopButton>{children}</WithScrollToTopButton>
      </main>
      <Footer />
    </Wrapper>
  );
};
