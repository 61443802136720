// Centered content container blocks
import { css } from '@emotion/core';
import { lighten } from 'polished';
import { colors } from 'styles';

export const FlexContainerCSS = css`
  display: flex;
`;

export const inner = css`
  margin: 0 auto;
  max-width: 1040px;
  width: 100%;
`;

export const innerFull = css`
  background-color: ${colors.offgrey};
  margin: 0 auto;
  width: 100%;
`;

export const outer = css`
  position: relative;
  padding: 0 4vw;
`;

export const ErrorTemplate = css`
  padding: 7vw 4vw;
`;

export const ErrorLink = css`
  display: inline-block;
  margin-top: 5px;
`;

export const Center = css`
  text-align: center;
`;

export const SocialLink = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 10px;
  color: ${colors.white};
  }

  svg {
    height: 1.8rem;
    fill: ${colors.white};
  }
`;

export const PostFeed = css`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 40px 0 0 0;
  @media (max-width: 650px) {
    margin: 0 10px;
  }
`;

export const PostFeedRaise = css`
  @media (min-width: 900px) {
    margin-top: -70px;
    padding-top: 0;
  }
`;

export const SiteMain = css`
  z-index: 100;
  flex-grow: 1;
`;

export const HomePosts = css`
  @media (min-width: 795px) {
    .post-card:nth-of-type(6n + 1):not(.no-image) {
      flex: 1 1 100%;
      flex-direction: row;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      border-radius: 5px 0 0 5px;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-content {
      flex: 0 1 357px;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) h2 {
      font-size: 2.6rem;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) p {
      font-size: 1.8rem;
      line-height: 1.55em;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-content-link {
      padding: 30px 40px 0;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-meta {
      padding: 0 40px 30px;
    }
  }
`;

// TODO extract into member styles
export const HiddenMobile = css`
  @media (max-width: 500px) {
    display: none;
  }
`;

export const AuthorProfileImage = css`
  display: flex;
  /* background: color(var(--lightgrey) l(+10%)); */
  background: ${lighten('0.1', colors.lightgrey)};
  border-radius: 100%;
  object-fit: cover;
  margin-right: 15px;
  width: 60px;
  height: 60px;
`;

export const SiteHeader = css`
  position: relative;
  color: ${colors.white};
  padding: 3.75rem;
  background: ${colors.green} no-repeat center center;
  background-size: cover;
`;

export const MeetHeader = css`
  position: relative;
  color: ${colors.white};
  padding: 0 3.75rem;
  background: ${colors.blue} no-repeat center center;
  background-size: cover;
`;

export const PageTemplate = css`
  .site-main {
    background: ${colors.white};
    padding-bottom: 4vw;
  }
`;

export const PostTemplate = css`
  .site-main {
    background: ${colors.white};
    padding-bottom: 4vw;
  }
`;

export const PostFull = css`
  position: relative;
  z-index: 50;
`;

export const NoImage = css`
  .post-full-content {
    padding-top: 0;
  }
  .post-full-content:before,
  .post-full-content:after {
    display: none;
  }
`;

export const Column = css`
  display: flex;
  flex-direction: column;
`;

export const TwoColumn = css`
  > *:first-of-type {
    flex: 2;
  }
  > *:nth-of-type(2) {
    flex: 1;
    > img {
      margin: 20px 0;
    }
  }
  > * {
    margin: 0 20px;
  }
`;
