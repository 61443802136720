export const outdoorTrackOrder = [
  '100 Meters',
  '200 Meters',
  '400 Meters',
  '800 Meters',
  '1500 Meters',
  '1 Mile',
  '2 Mile',
  '100 Meter Hurdles',
  '110 Meter Hurdles',
  '400 Meter Hurdles',
  '4x100 Meters',
  '4x400 Meters',
  '4x800 Meters',
  'High Jump',
  'Long Jump',
  'Triple Jump',
  'Pole Vault',
  'Shot Put',
  'Discus',
  'Javelin',
  'Pentathlon',
  'Decathlon',
  'Heptathlon',
  'Distance Medley',
  '4x200 Meters Relay',
  'Sprint Medley (800 Meters)',
  'Sprint Medley (1600 Meters)',
  '4x1600 Meters',
  '4x1 Mile',
  '4x110 Meters Shuttle Hurdles',
  '4x100 Meters Shuttle Hurdles',
  'Swedish Medley Relay',
  'Hammer Throw 4K',
  'Hammer Throw 5K',
  'Hammer Throw 6K',
  'Hammer Throw 12K',
  'Hammer Throw 12 Pound',
  'Hammer Throw 16 Pound',
  '10000 Meters',
  '3000 Meters Steeplechase',
  '2000 Meters Steeplechase',
  '300 Meter Low Hurdles',
  '1 Mile Race Walk',
  '2000 Meters',
  '3000 Meters',
  '3 Mile',
  '6 Mile',
  '220 Yards',
  '4x220 Yards',
  '4x440 Yards',
];
