// Formats the plural of the key. If it ends in an `s`, we add `es`
// otherwise we will add an `s`. May need to enhance this later.
export const formatPlural = (value: string | undefined) => {
  const isEndingInS = value ? value.endsWith('s') : null;
  const formattedPluralValue = isEndingInS
    ? value.slice(0, -2) + 'es'
    : `${value}s`;

  return `All ${formattedPluralValue}`;
};
