import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colors } from 'styles';

const FormContainerStyles = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
`;

export const FormContainer = styled.div`
  ${FormContainerStyles}
`;

export const Form = styled.form`
  ${FormContainerStyles}
`;

export const InputGroup = css`
  margin-bottom: 20px;
`;

export const SignInWrapper = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ErrorWrapper = css`
  margin: 2rem;
`;

export const ErrorText = css`
  color: ${colors.red};
  font-size: 1rem;
  line-height: 1rem;
  font-style: italic;
  margin: 1rem 0 0;
`;

export const ForgotPasswordWrapper = css`
  display: inline-block;
  margin-left: 0.5rem;
  color: ${colors.offgrey};
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 700;
`;
