import React, { useState, useEffect } from 'react';
import { Page, pdfjs } from 'react-pdf';

// Local styles
import { Flex, PageButton, StyledDocument } from './local-styles';

// Hooks
import { useWindowSize } from 'hooks';

// Types
type NumPages = number;
type PageNumber = number;

interface Props {
  url: string;
}

export const PDF = ({ url }: Props) => {
  const [numPages, setNumPages] = useState<NumPages>(0);
  let [pageNumber, setPageNumber] = useState<PageNumber>(1);
  const { width = 0 } = useWindowSize();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: NumPages }) =>
    setNumPages(numPages);
  const onNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber((pageNumber += 1));
    }
  };
  const onPrevPage = () => {
    if (pageNumber > 0) {
      setPageNumber((pageNumber -= 1));
    }
  };

  // Change the scale of the pdf based on the width of the page.
  const scale = width <= 575 ? 0.5 : 0.75;

  useEffect(() => {
    // Needed for react-pdf.
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, [url]);

  return (
    <StyledDocument file={url} onLoadSuccess={onDocumentLoadSuccess}>
      <Flex>
        <PageButton disabled={pageNumber === 1} onClick={onPrevPage}>
          &lt;
        </PageButton>

        <Page
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          scale={scale}
        />

        <PageButton disabled={pageNumber === numPages} onClick={onNextPage}>
          &gt;
        </PageButton>
      </Flex>
    </StyledDocument>
  );
};
