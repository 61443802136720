import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import React from 'react';

// Components
import { FluidImage } from 'components/PostCard/FluidImage';

// Local Styles
import { CardStyles, LargeCard, Title } from './local-styles';

// Hooks
import { getFluidImage } from 'hooks';

// Misc.
import { orderByMeet } from './orderByMeet';

// Types
import { Season } from 'types';

// TODO: Fix `any`
export interface Meet {
  meetName: string;
  coverPhoto?: any;
}

interface Props {
  allTimeMeets: Meet[];
  fallbackCover: any; // TODO: fix `any`.
  miaaMeets: Meet[];
  path: string;
  season: Season;
}

export const TrackSeasonCards = (props: Props) => {
  const {
    miaaMeets = [],
    allTimeMeets = [],
    season,
    fallbackCover,
    ...rest
  } = props;

  // Make sure we have a season.
  if (!season) {
    return null;
  }

  // Setup `All Time` placeholder.
  let allTimeCard = null;
  let trackMeets: Meet[] = [];

  // Check for extra meets.
  if (allTimeMeets?.[0]) {
    // We can use the `All Time` card.
    allTimeCard = { meetName: 'All Time', coverPhoto: fallbackCover };

    // Include the `All Time` card to the array.
    trackMeets = [allTimeCard];
  }

  // Each MIAA meet should have its own card.
  trackMeets = [...trackMeets, ...miaaMeets];

  return (
    <>
      {orderByMeet(trackMeets).map(({ meetName, coverPhoto }: Meet) => {
        const fluid =
          (coverPhoto?.[0] && getFluidImage(coverPhoto[0].data)) ||
          fallbackCover;

        return (
          <Link
            css={CardStyles}
            key={meetName}
            to={`${{ ...rest }.path}/${kebabCase(meetName)}`}
          >
            <LargeCard>
              {fluid && <FluidImage alt={meetName} fluid={fluid} />}
              <Title>{meetName}</Title>
            </LargeCard>
          </Link>
        );
      })}
    </>
  );
};
