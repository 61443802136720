import get from 'lodash/get';
import { HOF, Sponsor } from 'src/types';

/*
 * @function getFluidImage
 * @param {Object} base - typically `data` from a gql query
 * @param {String?} end - Optionally return the `src`, or `base64` etc.
 * @returns {Object}
 * @description - helper function to return the required fluid structure for a
 *                GQL query for <Img>.
 * */

export const getFluidImage = (base: HOF | Sponsor, end?: string) => {
  const fluidString = 'attachments.localFiles[0].childImageSharp.fluid';

  // Return the entire fluid object.
  if (!end) {
    return get(base, fluidString, '') || '';
  }

  // Otherwise, return just the key we want from the object.
  return get(base, `${fluidString}.${end}`, '') || '';
};
