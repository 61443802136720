import React, { useState, useEffect } from 'react';
import { animationThrottle } from './animationThrottle';
import { screens } from './screens';

import {
  desktopQuery,
  phoneQuery,
  tabletQuery,
  tabletUpQuery,
} from './queries';

const isWindowDefined = typeof window !== 'undefined';
const initialState = isWindowDefined && window.innerWidth;

const MediaQuery = (props) => {
  const { query, children, threshold } = props;
  const [windowWidth, setWindowWidth] = useState(initialState);

  useEffect(() => {
    animationThrottle('resize', 'optimizedResize');
    isWindowDefined && window.addEventListener('optimizedResize', setWidth);
  }, []);

  const setWidth = () => {
    setWindowWidth(isWindowDefined && window.innerWidth);
  };

  // Turn this into a hook.
  if (threshold) {
    return ({ [threshold.key]: [threshold.size] <= windowWidth });
  }

  // Use as a component.
  return isWindowDefined && window.matchMedia(query).matches ? children : null;
};

/**
 * Component exports
 */
export const Phone = (props) => <MediaQuery query={phoneQuery} {...props} />;
export const Tablet = (props) => <MediaQuery query={tabletQuery}{...props} />;
export const TabletUp = (props) => <MediaQuery query={tabletUpQuery} {...props} />;
export const Desktop = (props) => <MediaQuery query={desktopQuery} {...props} />;

/*
* Hook exports
* */
export const useIsAbovePhone = () => MediaQuery({
  threshold: {
    size: screens.phone,
    key: 'isAbovePhone',
  },
});

export const useIsAboveTablet = () => MediaQuery({
  threshold: {
    size: screens.tablet,
    key: 'isAboveTablet',
  },
});
