import { css } from '@emotion/core';
import styled from '@emotion/styled';
import {
  PostCardImage,
  PostCardStyles,
} from 'components/PostCard/local-styles';
import { colors } from 'styles';

export const Title = styled.h2`
  text-align: center;
  color: ${colors.darkgrey};
`;

export const LargeCard = styled(PostCardImage)<{ noTitle?: boolean }>`
  height: ${({ noTitle }) => (noTitle ? '100%' : '350px')};
  > img {
    height: 350px;
  }
`;

export const CardStyles = css`
  ${PostCardStyles};
  flex: 1 1 400px;
  min-height: 475px;
`;
