import { Link } from 'gatsby';
import React from 'react';

// Local Styles
import { AuthorCardButton, PostFullFooterRightDiv } from './local-styles';

// Types
export interface PostFullFooterRightProps {
  memberSlug: string;
}

export const PostFullFooterRight = ({
  memberSlug,
}: PostFullFooterRightProps) => (
  <PostFullFooterRightDiv>
    <Link css={AuthorCardButton} to={`/member/${memberSlug}/`}>
      Read More
    </Link>
  </PostFullFooterRightDiv>
);
