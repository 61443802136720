import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from 'styles';

export const HomeNavRaise = css`
  @media (min-width: 900px) {
    position: relative;
    top: -70px;
  }
`;

export const SiteNavStyles = css`
  position: relative;
  z-index: 300;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 40px;
  font-size: 1.2rem;
`;

export const SiteNavLeft = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-right: 10px;
  padding-bottom: 80px;
  letter-spacing: 0.4px;
  white-space: nowrap;

  @media (max-width: 700px) {
    margin-right: 0;
    padding-left: 4vw;
  }
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: ${colors.green};
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

  a {
    color: black;
    padding: 12px 16px;
    display: block;
    text-align: left;
  }
`;

export const ButtonStyles = css`
  display: block;
  margin: 0;
  padding: 10px 12px;
  color: ${colors.white};
  font-size: 1.4rem;
  background: transparent;
  text-transform: uppercase;
`;

export const NavStyles = css`
  display: flex;
  margin: 0 0 0 -12px;
  padding: 0;
  list-style: none;

  li {
    display: block;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    &.dropdown {
      &:hover {
        ${DropdownContent} {
          display: block;
        }
      }
    }
  }

  li a,
  li button {
    ${ButtonStyles}
  }

  li a:hover,
  li button:hover {
    text-decoration: none;
  }
`;

export const SiteNavRight = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 40px;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const SocialLinks = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

export const SubscribeButton = styled.a`
  display: block;
  padding: 4px 10px;
  border: ${colors.white} 1px solid;
  color: ${colors.white};
  font-size: 1.2rem;
  line-height: 1em;
  border-radius: 10px;
  opacity: 0.8;

  :hover {
    text-decoration: none;
    opacity: 1;
    cursor: pointer;
  }
`;

export const SiteNavLogoStyles = css`
  flex-shrink: 0;
  display: block;
  margin-right: 24px;
  padding: 11px 0;
  color: ${colors.white};
  font-size: 1.7rem;
  line-height: 1em;
  font-weight: bold;
  letter-spacing: -0.5px;

  :hover {
    text-decoration: none;
  }

  img {
    display: block;
    width: auto;
    height: 21px;
  }
`;
