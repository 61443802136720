/*
 * @function getInitials
 * @param {string} - name
 * @returns {string} - initials
 * @description - This function takes a string and returns initials
 * @example = ('Steve Burke') => 'SB'
 * */

export const getInitials = (name: string) => {
  if (!name) {
    return null;
  }

  const splitName = name.split(' ');

  return splitName
    .map(word => word.charAt(0).toUpperCase())
    .join('')
    .toString();
};
