import React, { PureComponent } from 'react';

// Local styles
import { BackToTopButton } from './local-styles';

// Types
export interface Props {
  buttonText?: String;
  isAboveTopThreshold: boolean;
  isScrollingDown: boolean;
  isScrollingUp: boolean;
}

const DELAY_IN_MS = 5;
const SCROLL_STEP_IN_PX = 50;
const isWindowDefined = typeof window !== 'undefined';

export class ScrollButton extends PureComponent<Props> {
  public state = { intervalId: 0 };

  public scrollStep = () => {
    const { intervalId } = this.state;

    // Clear interval if at the top of the page.
    if (isWindowDefined && window.pageYOffset === 0) {
      clearInterval(intervalId);
    }

    // Scrolling behavior
    isWindowDefined && window.scroll(0, window.pageYOffset - SCROLL_STEP_IN_PX);
  };

  public scrollToTop = () => {
    const intervalId = setInterval(this.scrollStep, DELAY_IN_MS);

    this.setState({ intervalId });
  };

  public render() {
    const {
      buttonText,
      isAboveTopThreshold,
      isScrollingDown,
      isScrollingUp,
    } = this.props;

    return (
      <BackToTopButton
        isAboveTopThreshold={isAboveTopThreshold}
        isScrollingDown={isScrollingDown}
        isScrollingUp={isScrollingUp}
        onClick={this.scrollToTop}
        role="button"
      >
        <div>{buttonText || 'Scroll to top'}</div>
      </BackToTopButton>
    );
  }
}
