import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { lighten } from 'polished';
import { colors } from 'styles';

export const Article = styled.article`
  column-count: 2;
  column-gap: 50px;
  padding: 50px;
  @media (max-width: 800px) {
    column-count: 1;
    column-gap: 50px;
  }
`;

export const PostCardStyles = css`
  border-radius: 5px;
  box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px,
    rgba(39, 44, 49, 0.03) 1px 3px 8px;
  padding: 3.25rem;
  background: ${lighten(0.145, colors.lightgrey)};
  .gatsby-image-wrapper {
    width: 100%;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    overflow-y: inherit !important;
    @media (max-width: 800px) {
      float: none;
      margin: 0 auto;
    }
  }
`;

export const Bio = styled.div`
  text-align: justify;
  line-height: 2.3;
  min-height: 600px;
  h1 {
    text-align: center;
  }
  p {
    margin-bottom: 0;
  }
`;

export const InductionInfo = styled.p`
  text-align: end;
`;

export const Section = styled.section`
  padding-bottom: 4rem;
`;
