import { ReactElement } from 'react';

// Types
interface Props {
  condition: boolean;
  children: ReactElement;
  wrapper(children: any): ReactElement;
}

export const ConditionalWrapper = (props: Props) => {
  const { condition, wrapper, children } = props;

  return condition ? wrapper(children) : children;
};
