export const indoorTrackOrder = [
  '55 Meters',
  '55 Meter Hurdles',
  '300 Meters',
  '600 Meters',
  '1000 Meters',
  '1 Mile',
  '2 Mile',
  '4x200 Meters',
  '4x800 Meters',
  '4x400 Meters',
  'Shot Put',
  'Shot Put (4K)',
  'Shot Put (12lb)',
  'High Jump',
  'Long Jump',
  'Pentathlon',
  '200 Meters',
  '400 Meters',
  '500 Meters',
  '800 Meters',
  '1500 Meters',
  '3000 Meters',
  '5000 Meters',
  'Triple Jump',
  'Pole Vault',
  'Weight Throw (20lb)',
  'Weight Throw (25lb)',
  'Weight Throw (35lb)',
  'Shot Put (8lb)',
  'Shot Put (16lb)',
  '1 Mile Steeplechase',
  '4x50 Yard Shuttle Dash',
  '4x50 Yard Shuttle Hurdle Relay',
  'Sprint Medley (1600 Meters)',
  'Distance Medley',
  '4x100 Meters',
  '50 Yards',
  '60 Yards',
  '60 Meters',
  '220 Yards',
  '300 Yards',
  '600 Yards',
  '880 Yards',
  '1000 Yards',
  '3 Mile',
  '4x220 Yards',
];
