import get from 'lodash/get';
import React from 'react';

// Components
import { FluidImage } from 'components/PostCard/FluidImage';

// Local styles
import {
  Article,
  Bio,
  InductionInfo,
  PostCardStyles,
  Section,
} from './local-styles';

// Hooks
import { getFluidImage } from 'hooks';

// Types
import { HOF } from 'src/types';
interface Props {
  data: HOF;
}

export const SingleHOFLayout = (props: Props) => {
  const { data } = props;
  const fullName = get(data, 'full_name');
  const title = get(data, 'title');
  const fluid = getFluidImage(data);
  const bio = get(data, 'bio');
  const fallbackImageProfile = get(data, 'fallbackProfileImage');
  const classYear = get(data, 'class_year');
  const inductionYear = get(data, 'induction_year');

  return (
    <Section>
      <Article css={[PostCardStyles]}>
        {fluid ? (
          <FluidImage
            alt={title}
            fluid={fluid}
            imgStyle={{ objectPosition: 'top' }}
          />
        ) : (
          <img
            alt={fullName}
            style={{ maxHeight: 300, maxWidth: 400, width: '100%' }}
            src={fallbackImageProfile}
          />
        )}
        <Bio>
          <p>{bio}</p>
          <hr />
          <InductionInfo>
            {classYear && (
              <i>
                <b>Class:</b> {classYear}
              </i>
            )}
            <i>
              <b>&nbsp; Inducted:</b> {inductionYear}
            </i>
          </InductionInfo>
        </Bio>
      </Article>
    </Section>
  );
};
