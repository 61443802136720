import { Link } from 'gatsby';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import React from 'react';

// Local Styles
import {
  ReadNextCardContent,
  ReadNextCardHeader,
  ReadNextCardHeaderSitetitle,
  ReadNextCardHeaderTitle,
  ReadNextCardStyles,
  ReadNextDivider,
} from './local-styles';

// Hooks
import { useHomeHero } from 'hooks';

// Misc.
import config from '../../website-config';
import { InfinityIcon } from '../icons';

// Types
export interface ReadNextCardStylesProps {
  coverImage: string;
}
export interface ReadNextProps {
  tags?: string[];
  title?: string;
  relatedContent: {
    nodes: Array<{
      data: RelatedContentData;
    }>;
  };
}

export interface RelatedContentData {
  time_to_read: number;
  title: string;
  excerpt: string;
  post_id: string;
  link: string;
}

export const OtherContentCard = ({
  relatedContent,
  tags,
  title,
}: ReadNextProps) => {
  const hero = useHomeHero();
  const nodes = get(relatedContent, 'nodes');

  return (
    <ReadNextCardStyles coverImage={hero.src}>
      <ReadNextCardHeader>
        <ReadNextCardHeaderSitetitle>
          &mdash; {config.title} &mdash;
          {title && <h4>{title}</h4>}
        </ReadNextCardHeaderSitetitle>
        {tags && tags.length > 0 && (
          <ReadNextCardHeaderTitle>
            <Link to={`/tags/${kebabCase(tags[0])}/`}>{tags[0]}</Link>
          </ReadNextCardHeaderTitle>
        )}
      </ReadNextCardHeader>
      <ReadNextDivider>
        <InfinityIcon />
      </ReadNextDivider>
      <ReadNextCardContent>
        <ul>
          {nodes.map(({ data }) => (
            <li key={data.title}>
              <Link to={data.link || `/post/${data.post_id}`}>
                {data.title}
              </Link>
            </li>
          ))}
        </ul>
      </ReadNextCardContent>
    </ReadNextCardStyles>
  );
};
