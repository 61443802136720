import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { colors } from 'styles';

export const PostCardStyles = css`
  display: flex;
  flex-direction: column;
  flex: 1 1 300px;
  margin: 0 20px 40px;
  min-height: 300px;
  overflow: hidden;
  background: ${colors.white} center center;
  background-size: cover;
  border-radius: 5px;
  box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px,
    rgba(39, 44, 49, 0.03) 1px 3px 8px;
  transition: all 0.5s ease;

  :hover {
    box-shadow: rgba(39, 44, 49, 0.07) 8px 28px 50px,
      rgba(39, 44, 49, 0.04) 1px 6px 12px;
    transition: all 0.4s ease;
    transform: translate3D(0, -1px, 0) scale(1.02);
  }
`;

export const PostCardImageLink = css`
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
`;

export const PostCardImage = styled.div`
  width: auto;
  background: ${colors.lightgrey} no-repeat center center;
  background-size: cover;
  > img {
    height: 200px;
  }
`;

export const PostCardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PostCardContentLink = css`
  position: relative;
  display: block;
  padding: 25px 25px 0;
  color: ${colors.darkgrey};

  :hover {
    text-decoration: none;
  }
`;

export const PostCardTags = styled.span`
  display: block;
  margin-bottom: 4px;
  color: ${colors.green};
  font-size: 1.2rem;
  line-height: 1.15em;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const PostCardTitle = styled.h2`
  margin-top: 0;
`;

export const PostCardExcerpt = styled.section`
  font-family: Georgia, serif;
  margin-top: 0.625rem;
`;

export const PostCardMeta = styled.footer<{ isCenter?: boolean }>`
  align-items: flex-end;
  display: flex;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'space-between')};
  padding: 0 25px 25px;
`;

export const AuthorList = styled.ul`
  display: flex;
  flex-wrap: wrap-reverse;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const AuthorListItem = styled.li`
  position: relative;
  flex-shrink: 0;
  margin: 0;
  padding: 0;

  :nth-of-type(1) {
    z-index: 10;
  }
  :nth-of-type(2) {
    z-index: 9;
  }
  :nth-of-type(3) {
    z-index: 8;
  }
  :nth-of-type(4) {
    z-index: 7;
  }
  :nth-of-type(5) {
    z-index: 6;
  }
  :nth-of-type(6) {
    z-index: 5;
  }
  :nth-of-type(7) {
    z-index: 4;
  }
  :nth-of-type(8) {
    z-index: 3;
  }
  :nth-of-type(9) {
    z-index: 2;
  }
  :nth-of-type(10) {
    z-index: 1;
  }
  :hover .author-name-tooltip {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const AuthorNameTooltip = styled.div`
  position: absolute;
  bottom: 105%;
  display: block;
  padding: 2px 8px;
  color: ${colors.white};
  font-size: 1.2rem;
  letter-spacing: 0.2px;
  white-space: nowrap;
  background: ${colors.darkgrey};
  border-radius: 3px;
  box-shadow: rgba(39, 44, 49, 0.08) 0 12px 26px,
    rgba(39, 44, 49, 0.03) 1px 3px 8px;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transform: translateY(6px);
  pointer-events: none;

  @media (max-width: 650px) {
    display: none;
  }
`;

export const StaticAvatar = css`
  display: block;
  overflow: hidden;
  margin: 0 -5px;
  width: 34px;
  height: 34px;
  border: ${colors.white} 2px solid;
  border-radius: 100%;
`;

export const ReadingTime = styled.span`
  flex-shrink: 0;
  margin-left: 20px;
  color: ${colors.midgrey};
  font-size: 1.3rem;
  line-height: 33px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;
