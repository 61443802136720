import React, { ChangeEvent } from 'react';

// Local Styles
import { StyledFilter } from 'components/Filter/styles';
import { Input, Slider, Switch } from './local-styles';

// Types
interface Props {
  defaultChecked?: boolean;
  name: string;
  title?: string;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
}

export const ToggleSwitch = (props: Props) => {
  const { defaultChecked, title, onChange, name } = props;

  return (
    <StyledFilter>
      <h3>{title}</h3>
      <label css={Switch} htmlFor={name}>
        <input
          css={Input}
          defaultChecked={defaultChecked}
          id={name}
          name={name}
          onChange={onChange}
          type="checkbox"
          value="checkbox"
        />
        <span css={Slider} />
      </label>
    </StyledFilter>
  );
};
