import React from 'react';

// Local Styles
import { AuthorProfileBioImage } from './local-styles';

// Styles
import { AuthorProfileIcon, AuthorProfileImage } from 'styles';

// Misc.
import { getInitials } from 'utils';

// Types
interface Props {
  name: string;
  profileImage?: string;
}

export const MemberAvatar = ({ profileImage, name }: Props) =>
  profileImage ? (
    <img
      alt={name}
      css={[AuthorProfileImage, AuthorProfileBioImage]}
      src={profileImage}
    />
  ) : (
    <AuthorProfileIcon
      css={[AuthorProfileImage, AuthorProfileBioImage]}
      size="lg"
    >
      {getInitials(name)}
    </AuthorProfileIcon>
  );
