import React, { useState, useEffect, ReactElement } from 'react';
import { Link } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import kebabCase from 'lodash/kebabCase';

// Components
import { FluidImage } from 'components/PostCard/FluidImage';

// Local Styles
import { LargeCard, CardStyles, Title } from './local-styles';

// Hooks
import {
  useIndoorAllState,
  useOutdoorAllState,
  useXCAllState,
  useXCCentralMass,
  useXCEMass,
  useXCStatewide,
  useXCWesternMass,
} from 'hooks';

// Types
import { Season } from 'types';

export enum SeasonImageTitles {
  ALL_STATE = 'All State',
  CENTRAL_MASS = 'Central Mass',
  EASTERN_MASS = 'Eastern Mass',
  MIAA_INDOOR_ALL_STATE_CHAMPIONS = 'MIAA Indoor All State Champions',
  MIAA_OUTDOOR_ALL_STATE_CHAMPIONS = 'MIAA Outdoor All State Champions',
  STATEWIDE = 'Statewide',
  WESTERN_MASS = 'Western Mass',
}

interface Props {
  dataArray: string[];
  season: Season;
  path: string;
}

interface ISeasonImages {
  [SeasonImageTitles.ALL_STATE](): FluidObject;
  [SeasonImageTitles.CENTRAL_MASS](): FluidObject;
  [SeasonImageTitles.EASTERN_MASS](): FluidObject;
  [SeasonImageTitles.MIAA_INDOOR_ALL_STATE_CHAMPIONS](): FluidObject;
  [SeasonImageTitles.MIAA_OUTDOOR_ALL_STATE_CHAMPIONS](): FluidObject;
  [SeasonImageTitles.STATEWIDE](): FluidObject;
  [SeasonImageTitles.WESTERN_MASS](): FluidObject;
}

const SEASON_IMAGES = {
  [SeasonImageTitles.ALL_STATE]: useXCAllState,
  [SeasonImageTitles.CENTRAL_MASS]: useXCCentralMass,
  [SeasonImageTitles.EASTERN_MASS]: useXCEMass,
  [SeasonImageTitles.STATEWIDE]: useXCStatewide,
  [SeasonImageTitles.WESTERN_MASS]: useXCWesternMass,
  [SeasonImageTitles.MIAA_INDOOR_ALL_STATE_CHAMPIONS]: useIndoorAllState,
  [SeasonImageTitles.MIAA_OUTDOOR_ALL_STATE_CHAMPIONS]: useOutdoorAllState,
};

export const ChampionsSeasonCards = (props: Props): ReactElement => {
  const { dataArray, season, ...rest } = props;
  const [seasonImages, setSeasonImages] = useState(
    SEASON_IMAGES as ISeasonImages
  );

  useEffect(() => {
    if (season) {
      setSeasonImages(SEASON_IMAGES);
    }
  }, [season]);

  return (
    <>
      {dataArray.map((meetName) => {
        return (
          <Link
            css={CardStyles}
            key={meetName}
            to={`${{ ...rest }.path}/${kebabCase(meetName)}`}
          >
            <LargeCard>
              {seasonImages[meetName] && (
                <FluidImage alt={meetName} fluid={seasonImages[meetName]()} />
              )}
              <Title>{meetName}</Title>
            </LargeCard>
          </Link>
        );
      })}
    </>
  );
};
