import React from 'react';

// Local Styles
import {
  FormGroup,
  SubscribeEmail,
  SubscribeFormButton,
  SubscribeFormStyles,
} from './local-styles';

// Misc.
import config from '../../website-config';

export const SubscribeForm = () => {
  return (
    <form
      css={SubscribeFormStyles}
      action={config.mailchimpAction}
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      noValidate={true}
    >
      {/* This is required for the form to work correctly  */}
      <FormGroup className="form-group">
        <SubscribeEmail
          className="subscribe-email"
          type="email"
          name={config.mailchimpEmailFieldName}
          id={config.mailchimpEmailFieldName}
          placeholder="youremail@example.com"
        />
      </FormGroup>
      <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
        <input type="text" name={config.mailchimpName} tabIndex={-1} />
      </div>
      <SubscribeFormButton type="submit">
        <span>Subscribe</span>
      </SubscribeFormButton>
    </form>
  );
};
