import get from 'lodash/get';

/*
 * @function getMarkdown
 * @param {Data} data - data from graphQL Airtable
 * @returns {string}
 * @description - helper function to return the markdown.
 * */

export const getMarkdown = (data: any): string =>
  get(data, 'markdown_content', '') || '';
