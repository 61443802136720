import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

// Local Styles
import { SubscribeOverlayLogo } from './local-styles';

// Misc.
import config from '../../website-config';

// Types
interface SiteNavLogoProps {
  logo?: {
    childImageSharp: {
      fixed: any;
    };
  };
}

export const SubscribeLogo = () => (
  <StaticQuery
    query={graphql`
      query SubscribeOverlayLogo {
        logo: file(relativePath: { eq: "img/ghost-logo.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    // tslint:disable-next-line:react-this-binding-issue
    render={(data: SiteNavLogoProps) =>
      data.logo && (
        <SubscribeOverlayLogo
          src={data.logo.childImageSharp.fixed.src}
          alt={config.title}
        />
      )
    }
  />
);
