import styled from '@emotion/styled';

export const SiteDescription = styled.h2`
  z-index: 10;
  margin: 0;
  padding: 5px 0;
  font-size: 2.2rem;
  font-weight: 400;
  letter-spacing: 0.5px;
`;
