import { css } from '@emotion/core';
import { colors } from 'styles';

export const Switch = css`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
`;

export const Slider = css`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.blue};
  transition: 0.4s;
  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: ${colors.offwhite};
    transition: 0.4s;
  }
`;

export const Input = css`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked {
    + span {
      background-color: ${colors.green};
      &:before {
        transform: translateX(32px);
      }
    }
  }
  &:focus {
    + span {
      box-shadow: 0 0 1px ${colors.midgrey};
    }
  }
`;
