import React, { ReactNode } from 'react';

// Local Styles
import { PostFullFoot } from './local-styles';

// Types
interface Props {
  children: ReactNode;
}

export const PostFullFooter = ({ children }: Props) => (
  <PostFullFoot>{children}</PostFullFoot>
);
