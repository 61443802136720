import React, { useState, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useIdentityContext } from 'react-netlify-identity-gotrue';

// Styles
import { Button, Column, Input, Label } from 'styles';
import { ErrorText, Form, FormContainer, InputGroup } from '../local-styles';

// Constants
import { validEmailRegex } from '../constants';

// Types
interface IAuthForm {
  email: string;
  password: string;
}

export const Login = (): ReactElement => {
  const identity = useIdentityContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<IAuthForm>();
  const [formError, setFormError] = useState<string>('');
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  const onSubmit = async (): Promise<void> => {
    setIsLoggingIn(true);
    setFormError('');
    const { email, password } = getValues();

    await identity
      .login({ email, password })
      .then(() => {
        setIsLoggingIn(false);
        // Hack way to close the modal after completion.
        document.querySelectorAll('._mscta-simple-mdl-close-btn')[0].click();
      })
      .catch((e: { message: string | ((prevState: string) => string) }) => {
        setIsLoggingIn(false);
        setFormError(e.message);
      });
  };

  return identity.user ? (
    <FormContainer>
      <p>You are already signed in</p>
      <p>
        You may need to change accounts if you're looking to access further
        private data
      </p>
    </FormContainer>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {/* Email */}
      <div css={InputGroup}>
        <Label htmlFor="email">Email</Label>
        <Input
          {...register('email', {
            required: true,
            pattern: validEmailRegex,
          })}
          type="text"
          placeholder="jane@doe.com"
          name="email"
        />
        {errors.email && <p css={ErrorText}>Email is required</p>}
      </div>

      {/* Password */}
      <div css={InputGroup}>
        <Label htmlFor="password">Password</Label>
        <Input
          {...register('password', { required: true })}
          autoComplete="current-password"
          name="password"
          type="password"
          placeholder="******************"
        />
        {errors.password && <p css={ErrorText}>Password is required</p>}
      </div>

      {/* Actions */}
      <div css={Column}>
        <Button disabled={isLoggingIn} type="submit">
          Log in
        </Button>
        <br />
        <div css={InputGroup}>
          <a
            href="mailto:sburke0708@gmail.com?subject=Please reset my password in MSTCA"
            target="_blank"
          >
            Reset my password
          </a>
        </div>
      </div>
      <div className="pt-2">
        {formError && <p css={ErrorText}>{formError}</p>}
      </div>
    </Form>
  );
};
