import { Link } from 'gatsby';
import get from 'lodash/get';
import React from 'react';

// Components
import { AuthorAvatar } from 'components';

// Local Styles
import {
  AuthorCardContent,
  AuthorCardName,
  AuthorCardSection,
} from './local-styles';

// Types
export interface AuthorCardProps {
  author: {
    first_name: string;
    last_name: string;
    member_slug: string;
    bio?: string;
    fullName: string;
    profile_picture?: {
      url: string;
    };
  };
}

export const AuthorCard = ({ author }: AuthorCardProps) => {
  const profilePicture = get(author, 'profile_picture[0].url', '');
  const fullName = get(author, 'full_name', '');
  const memberSlug = get(author, 'member_slug', '');
  const bio = get(author, 'bio', '');

  return (
    <AuthorCardSection>
      <AuthorAvatar
        fullName={fullName}
        memberSlug={memberSlug}
        profilePicture={profilePicture}
      />
      <AuthorCardContent>
        <AuthorCardName>
          <Link to={`/member/${memberSlug}/`}>{fullName}</Link>
        </AuthorCardName>
        {bio ? (
          <p>{bio}</p>
        ) : (
          <p>
            Read <Link to={`/member/${memberSlug}/`}>more posts</Link> by this
            author.
          </p>
        )}
      </AuthorCardContent>
    </AuthorCardSection>
  );
};
