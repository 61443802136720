import { graphql, useStaticQuery } from 'gatsby';

export const use404PageSectionsQuery = () => {
  const {
    allAirtable: { nodes },
  } = useStaticQuery(graphql`
    {
      allAirtable(
        filter: { table: { eq: "Posts" } }
        limit: 3
        sort: { order: DESC, fields: data___Posts___data___created_date }
      ) {
        nodes {
          data {
            time_to_read
            excerpt
            post_id
            title
            created_date(formatString: "MM-DD-YYYY")
            tags
            author {
              data {
                first_name
                full_name
                member_slug
              }
            }
            attachments {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 3720) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return {
    sections: nodes || [],
  };
};
