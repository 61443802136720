import { graphql, useStaticQuery } from 'gatsby';

export const useAboutPageSectionsQuery = () => {
  const {
    allAirtable: { nodes },
  } = useStaticQuery(graphql`
    {
      allAirtable(filter: { table: { eq: "About Page" } }) {
        nodes {
          id
          data {
            markdown_content
            section_title
            attachments {
              id
              localFiles {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return { sections: nodes || [] };
};
