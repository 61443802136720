import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { darken, desaturate, lighten, mix } from 'polished';

import { colors } from 'styles';

// Types
interface SubscribeOverlayProps {
  isOpen?: boolean;
}

export const StyledSubscribeOverlay = styled.div<SubscribeOverlayProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9000;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 25, 40, 0.97);
  opacity: ${({ isOpen }: SubscribeOverlayProps) => (isOpen ? 1 : 0)};
  transition: opacity 200ms ease-in;
  pointer-events: ${({ isOpen }: SubscribeOverlayProps) =>
    isOpen ? 'auto' : 'none'};
  backdrop-filter: blur(3px);

  form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 500px;
  }

  .form-group {
    flex-grow: 1;
  }

  .subscribe-email {
    display: block;
    padding: 14px 20px;
    width: 100%;
    border: none;
    color: ${colors.midgrey};
    font-size: 2rem;
    line-height: 1em;
    font-weight: normal;
    letter-spacing: 0.5px;
    user-select: text;
    border-radius: 8px;
    transition: border-color 0.15s linear;

    -webkit-appearance: none;
  }

  button {
    display: inline-block;
    margin: 0 0 0 15px;
    padding: 0 25px;
    height: 52px;
    outline: none;
    color: ${colors.white};
    font-size: 1.7rem;
    line-height: 37px;
    font-weight: 400;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
    background: linear-gradient(
      color(var(--blue) whiteness(+7%)),
      color(var(--blue) lightness(-7%) saturation(-10%)) 60%,
      color(var(--blue) lightness(-7%) saturation(-10%)) 90%,
      color(var(--blue) lightness(-4%) saturation(-10%))
    );
    border-radius: 8px;
    box-shadow: 0 0 0 1px inset rgba(0, 0, 0, 0.14);

    -webkit-font-smoothing: subpixel-antialiased;
  }
`;

export const SubscribeOverlayClose = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;

  :before {
    content: '';
    position: absolute;
    top: 40px;
    right: 25px;
    display: block;
    width: 30px;
    height: 2px;
    background: ${colors.white};
    opacity: 0.8;
    transform: rotate(45deg);
  }

  :after {
    content: '';
    position: absolute;
    top: 40px;
    right: 25px;
    display: block;
    width: 30px;
    height: 2px;
    background: ${colors.white};
    opacity: 0.8;
    transform: rotate(-45deg);
  }

  :hover {
    cursor: default;
  }
`;

export const SubscribeOverlayContent = styled.div`
  position: relative;
  z-index: 9999;
  margin: 0 0 5vw 0;
  padding: 4vw;
  color: ${colors.white};
  text-align: center;
`;

export const SubscribeOverlayTitle = styled.h1`
  display: inline-block;
  margin: 0 0 10px 0;
  font-size: 6rem;
  line-height: 1.15em;
`;

export const SubscribeOverlayDescription = styled.p`
  margin: 0 auto 50px;
  max-width: 650px;
  font-family: Georgia, serif;
  font-size: 3rem;
  line-height: 1.3em;
  font-weight: 300;
  opacity: 0.8;
`;

// SubscribeForm
export const SubscribeFormStyles = css`
  @media (max-width: 500px) {
    -ms-flex-direction: column;
    flex-direction: column;
  }
`;

export const SubscribeEmail = styled.input`
  display: block;
  padding: 10px;
  width: 100%;
  /* border: color(var(--lightgrey) l(+7%)) 1px solid; */
  border: ${lighten('0.07', colors.lightgrey)};
  color: ${colors.midgrey};
  font-size: 1.8rem;
  line-height: 1em;
  font-weight: normal;
  user-select: text;
  border-radius: 5px;
  transition: border-color 0.15s linear;

  -webkit-appearance: none;
  :focus {
    outline: 0;
    /* border-color: color(var(--lightgrey) l(-2%)); */
    border-color: ${darken('0.02', colors.lightgrey)};
  }
`;

export const SubscribeFormButton = styled.button`
  display: inline-block;
  margin: 0 0 0 10px;
  padding: 0 20px;
  height: 41px;
  outline: none;
  color: ${colors.white};
  font-size: 1.5rem;
  line-height: 37px;
  font-weight: 400;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  /* background: linear-gradient(
    color(var(--blue) whiteness(+7%)),
    color(var(--blue) lightness(-7%) saturation(-10%)) 60%,
    color(var(--blue) lightness(-7%) saturation(-10%)) 90%,
    color(var(--blue) lightness(-4%) saturation(-10%))
  ); */
  background: linear-gradient(
    ${mix('0.1', colors.white, colors.blue)},
    ${desaturate('0.1', darken('0.07', colors.blue))} 60%,
    ${desaturate('0.1', darken('0.07', colors.blue))} 90%,
    ${desaturate('0.1', darken('0.04', colors.blue))}
  );
  border-radius: 5px;
  box-shadow: 0 0 0 1px inset rgba(0, 0, 0, 0.14);

  -webkit-font-smoothing: subpixel-antialiased;

  :active,
  :focus {
    /* background: color(var(--blue) lightness(-9%) saturation(-10%)); */
    background: ${desaturate('0.1', darken('0.09', colors.blue))};
  }
  @media (max-width: 500px) {
    margin: 10px 0 0;
    width: 100%;
  }
`;

export const FormGroup = styled.div`
  flex-grow: 1;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const SubscribeFormSection = styled.section<{
  isCentered?: boolean;
  small?: boolean;
}>`
  margin: ${({ isCentered }) => (isCentered ? '1.5em auto 5rem' : '1.5em 0')};
  padding: ${({ small }) => (small ? '1.5vw 4vw  1.5vw' : '6.5vw 7vw 7vw')};
  border: ${lighten('0.02', colors.whitegrey)} 1px solid;
  text-align: center;
  background: ${colors.green};
  position: relative;
  border-radius: 7px;
  a {
    color: ${colors.white};
  }
  p {
    margin: 1rem;
    color: ${colors.white};
    font-size: 2.2rem;
    line-height: 1.55em;
    letter-spacing: 0.2px;
  }
  form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 420px;
  }
  .form-group {
    flex-grow: 1;
  }
  @media (max-width: 650px) {
    p {
      font-size: 1.6rem;
    }
  }
`;

export const SubscribeFormTitle = css`
  margin: 0 0 3px 0;
  padding: 0;
  color: ${colors.white};
  font-size: 3.5rem;
  line-height: 1;
  font-weight: 700;
  @media (max-width: 650px) {
    font-size: 2.4rem;
  }
`;

export const SubscribeOverlayLogo = styled.img`
  position: fixed;
  top: 23px;
  left: 30px;
  height: 30px;
`;
