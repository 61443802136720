import Img, { FluidObject } from 'gatsby-image';
import React from 'react';

// Types
interface Props {
  alt: string;
  fluid: FluidObject;
  imgStyle?: object;
  isAbsolute?: boolean;
}

export const FluidImage = ({ alt, fluid, isAbsolute, ...rest }: Props) => {
  const style = !isAbsolute
    ? { height: '100%' }
    : { position: 'absolute', top: '0', right: '0', left: '0' };

  return (
    <Img
      alt={alt}
      fluid={fluid}
      imgStyle={{ objectPosition: '0% 0%' }}
      style={style}
      {...rest}
    />
  );
};
