import { FluidObject } from 'gatsby-image';
import React from 'react';

// Local-styles
import { FluidImage } from 'components/PostCard/FluidImage';
import { FallbackImg } from './local-styles';

// Types
interface Props {
  alt: string;
  fluid?: FluidObject;
  fallbackImgSrc?: string;
}

export const ProfileImage = ({ alt, fluid, fallbackImgSrc }: Props) => {
  // If we have a fluid obj, use that.
  if (fluid) {
    return <FluidImage alt={alt} fluid={fluid} />;
  }

  // Otherwise return the fallback src.
  return <FallbackImg alt={alt} src={fallbackImgSrc} />;
};
