import styled from '@emotion/styled';
import { colors } from 'styles';

export const Wrapper = styled.div`
  min-height: 25rem;
  border: 1px solid ${colors.midgrey};
  display: flex;
  align-items: center;
  justify-content: center;
`;
