import React, { ChangeEvent, ReactElement } from "react";
import uniq from "lodash/uniq";

// Local styles
import { Select, StyledFilter } from "components/Filter/styles";
import { getCurrentSeason } from "../../utils";
import { SEASON } from "../../types";

// Types
interface Props {
  includesSeasonAndYear?: boolean;
  isDisabled: boolean;
  isMostRecent?: boolean;
  name: string;
  options: [];
  title: string;

  onChange(e: ChangeEvent<HTMLInputElement>): void;
}

export const Filter = (props: Props): ReactElement => {
  const {
    includesSeasonAndYear,
    isDisabled,
    isMostRecent,
    name,
    onChange,
    options,
    title
  } = props;
  const isIndoorTrack: boolean = getCurrentSeason() === SEASON.INDOOR_TRACK;
  const fullYear: number = new Date().getFullYear();
  const getOrderedOptions = (): string[] => {
    // Indoor track is a special case.
    // We want to show the previous year in the filter but only after the new year.
    if (includesSeasonAndYear && name === "year" && isIndoorTrack) {
      if (new Date().getMonth() >= 9) {
        return uniq([(fullYear).toString(), ...options]);
      } else {
        return uniq([(fullYear - 1).toString(), ...options]);
      }
    }
    if (isMostRecent) {
      return uniq([fullYear.toString(), ...options]);
    }
    return options;
  };

  return (
    <StyledFilter>
      <h3>{title}</h3>
      <Select
        disabled={isDisabled}
        name={name}
        // @ts-ignore
        onChange={onChange}
      >
        {getOrderedOptions()
          .filter((option: string): string => option)
          .map((data: number | string) => {
            const value = data?.toString();

            return (
              <option key={value} value={value}>
                {data}
              </option>
            );
          })}
      </Select>
    </StyledFilter>
  );
};
