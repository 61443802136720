/*
 * Layout components are for sections of your site that
 * you want to share across multiple pages.
 * */

import React, { ReactNode } from 'react';
import { Global } from '@emotion/core';
import { ModalProvider } from 'react-simple-hook-modal';

// Components
import { SEO } from 'components';

// Styles
import { GlobalStyles } from 'styles';

// Assets
// @ts-ignore
import favicon from 'images/favicons/favicon.ico';
import AuthOverlay from '../../components/AuthOverlay';

// Types
interface Props {
  children: ReactNode;
  className?: string;
  title?: string;
}

export const SEOContent = (props: Props) => {
  const { children, className, title } = props;

  return (
    <ModalProvider>
      <div className={className}>
        <SEO title={title} />
        <Global styles={GlobalStyles} />
        <AuthOverlay />
        {children}
      </div>
    </ModalProvider>
  );
};
