import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { Login } from 'forms';

const AuthOverlay = () => {
  const identity = useIdentityContext();

  const { register, handleSubmit, errors } = useForm();
  const [formError, setFormError] = useState();
  const [formProcessing, setFormProcessing] = useState(false);
  const [forceShowOverlay, setForceShowOverlay] = useState(false);

  useEffect(() => {
    if (identity.provisionalUser) {
      setForceShowOverlay(
        'Please check your email for an account confirmation email!'
      );
      const timeoutId = setTimeout(() => setForceShowOverlay(false), 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [identity.provisionalUser]);

  const onSubmit = async (data) => {
    setFormProcessing(true);
    setFormError();

    await identity
      .completeUrlTokenTwoStep(data)
      .catch((_) => setFormError('Having an issue.. please try later'));

    setFormProcessing(false);
  };

  return (
    <>
      {(identity.urlToken || forceShowOverlay) && (
        <div className="w-full h-full fixed block top-0 left-0 bg-gray-200 bg-opacity-75 z-50 flex justify-center items-center">
          <div className="w-full p-4 max-w-xs opacity-100 bg-white shadow-xl rounded-lg">
            {identity.urlToken?.type === 'confirmation' && (
              <p>Confirming User...</p>
            )}
            {identity.urlToken?.type === 'email_change' &&
              (identity.user ? (
                <p>Changing Email...</p>
              ) : (
                <>
                  <p>
                    In order to confirm your email change, you must log in with
                    your prior credentials.
                  </p>
                  <Login navigateTarget="/" />
                </>
              ))}
            {forceShowOverlay && <p>{forceShowOverlay}</p>}
            {(identity.urlToken?.type === 'passwordRecovery' ||
              identity.urlToken?.type === 'invite') && (
              <>
                {identity.urlToken.type === 'passwordRecovery' && (
                  <h2>Reset Password</h2>
                )}
                {identity.urlToken.type === 'invite' && (
                  <>
                    <h2>Welcome</h2>
                    <p className="mb-0">
                      Let's complete the rest of your account info
                    </p>
                  </>
                )}
                some other form
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AuthOverlay;
