import moment from 'moment';

// Types
import { Filters } from 'types';

export const filterBy = (gqlArray: Filters[], filter) => {
  const selectedFilterYear = +filter.year;
  const selectedFilterSeason = filter?.season;
  const filterKeys: string[] = Object.keys(filter || {});
  // Find the filters that evaluate to true.
  // ex: `filter: { pole_vault_coaches: true}` => ["pole_vault_coaches"]
  const trueFilters = filterKeys.filter(
    (entry: string): boolean => filter[entry] === true
  );

  // This is meant for check box filters only.
  if (trueFilters.length) {
    return gqlArray.filter((item: Filters) => {
      for (let key in trueFilters) {
        // @ts-ignore
        return !!item[trueFilters[key]];
      }
      return null;
    });
  }

  return gqlArray.filter((item: Filters) => {
    for (let key in filter) {
      if (typeof filter[key] === 'boolean' || filter[key].includes('All')) {
        // @ts-ignore
        delete filter[key];
      }

      // If the season is `Indoor track`, then we want to show the events
      // in the current year + 1
      // and only between 11/24 & 03/15 - see `getCurrentSeason`.
      if (selectedFilterSeason === 'Indoor track' && !isNaN(+filter.year)) {
        return moment(item.meet_date).isBetween(
          `${selectedFilterYear}-11-24`,
          `${selectedFilterYear + 1}-03-15`
        );
      } else if (
        item[key as keyof Filters] === undefined ||
        // @ts-ignore
        item[key as keyof Filters] !== filter[key]
      ) {
        return false;
      }
    }
    return true;
  });
};
