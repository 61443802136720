import { graphql, useStaticQuery } from 'gatsby';

export const useHomePageQuery = () => {
  const {
    allAirtable: { nodes },
  } = useStaticQuery(graphql`
      {
          allAirtable(
              filter: { data: { front_page_featured: { eq: true } } }
              sort: { fields: data___created_date, order: DESC }
          ) {
              nodes {
                  fields {
                      postId
                  }
                  data {
                      attachments {
                          localFiles {
                              childImageSharp {
                                  fluid {
                                      ...GatsbyImageSharpFluid
                                  }
                              }
                          }
                      }
                      created_date(formatString: "MMM. Do, YYYY")
                      excerpt
                      time_to_read
                      title
                      post_id
                      tags
                      author {
                          data {
                              first_name
                              full_name
                              member_slug
                          }
                      }
                      advertisements {
                          data {
                              external_url
                              attachments {
                                  localFiles {
                                      childImageSharp {
                                          fluid {
                                              ...GatsbyImageSharpFluid
                                          }
                                      }
                                  }
                              }
                              post_position
                          }
                      }
                  }
              }
          }
      }
  `);
  return nodes;
};
