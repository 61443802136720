import React, { ReactElement } from 'react';
import { CardStyles, LargeCard, Title } from '../SeasonCards/local-styles';
import { FluidImage } from '../PostCard/FluidImage';
import { useLogo } from '../../hooks';

interface IProps {
  title?: string;
}
export const PlaceholderCard = (props: IProps): ReactElement => {
  const { title } = props;
  const logo = useLogo();

  return (
    <div css={CardStyles}>
      <LargeCard noTitle={!title}>
        <FluidImage alt={title || 'MSTCA'} fluid={logo} />
        {title && <Title>{title}</Title>}
      </LargeCard>
    </div>
  );
};
