import snakeCase from 'lodash/snakeCase';
import toLower from 'lodash/toLower';
import React, { ChangeEvent } from 'react';

// Components
import { Filter } from 'components/Filter';

// Types
import { IFilter } from 'types';
interface UniqueOptions {
  gqlKey: string;
  value: string;
  hasAllOption: boolean;
}
interface Props {
  filters: IFilter[];
  isSelectFiltersDisabled: boolean;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  uniqueOptions({ gqlKey, value, hasAllOption }: UniqueOptions): void;
}

export const FiltersArray = (props: Props) => {
  const { filters, isSelectFiltersDisabled, onChange, uniqueOptions } = props;

  const includesSeason = !!filters.find(
    ({ title }: IFilter): boolean => title === 'Season'
  );
  const includesYear = !!filters.find(
    ({ title }: IFilter): boolean => title === 'Year'
  );
  const includesSeasonAndYear: boolean = includesSeason && includesYear;

  return (
    <>
      {filters.map(({ title, gqlKey, hasAllOption, isMostRecent }: IFilter) => {
        const value: string = toLower(title);

        // Ensure it is snake case.
        const snake: string = snakeCase(gqlKey);

        return (
          <Filter
            isDisabled={isSelectFiltersDisabled}
            isMostRecent={isMostRecent}
            includesSeasonAndYear={includesSeasonAndYear}
            key={value}
            name={snake}
            onChange={onChange}
            options={uniqueOptions({ gqlKey: snake, value, hasAllOption })}
            title={title}
          />
        );
      })}
    </>
  );
};
