import { FluidObject } from 'gatsby-image';

// Types
interface Image {
  childImageSharp: {
    fluid: FluidObject;
  };
}

/*
 * @function getAttachmentsArray
 * @param {Data} attachments[] - array of fluid objects
 * @returns {AttachmentsArray[]}
 * @description - helper function to return an array of fluid objects.
 * */

export const getAttachmentsArray = (attachments: any = []) =>
  attachments.map((image: Image) => {
    if (image.childImageSharp) {
      return image.childImageSharp.fluid;
    }
    return [];
  });
