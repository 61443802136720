import { graphql, useStaticQuery } from 'gatsby';

export const useXCCentralMass = () => {
  const { logo } = useStaticQuery(graphql`
      {
          logo: file(relativePath: { eq: "cross-country/central-mass.jpg" }) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `);
  return logo.childImageSharp.fluid;
};
