import React, { PureComponent } from 'react';

// Components
import { SubscribeForm, SubscribeLogo } from 'components';

// Local Styles
import {
  StyledSubscribeOverlay,
  SubscribeOverlayClose,
  SubscribeOverlayContent,
  SubscribeOverlayDescription,
  SubscribeOverlayTitle,
} from './local-styles';

// Misc.
import config from '../../website-config';

// Types
interface State {
  isOpen: boolean;
}

// TODO: refactor using hooks.
export class SubscribeOverlay extends PureComponent<{}, State> {
  public state = { isOpen: false };

  public componentWillUnmount() {
    this.unsubscribeEsc();
  }

  public escFunction = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.close();
    }
  };

  public subscribeEsc() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  public unsubscribeEsc() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  public open = () => {
    this.setState({ isOpen: true });
    this.subscribeEsc();
  };

  public close = () => {
    this.setState({ isOpen: false });
    this.unsubscribeEsc();
  };

  public render() {
    return (
      <StyledSubscribeOverlay isOpen={this.state.isOpen}>
        <SubscribeOverlayClose onClick={this.close} />
        <SubscribeOverlayContent>
          <SubscribeLogo />
          <SubscribeOverlayTitle>
            Subscribe to {config.title}
          </SubscribeOverlayTitle>
          <SubscribeOverlayDescription>
            Stay up to date! Get all the latest &amp; greatest posts delivered
            straight to your inbox.
          </SubscribeOverlayDescription>
          <SubscribeForm />
        </SubscribeOverlayContent>
      </StyledSubscribeOverlay>
    );
  }
}
