import React, { ReactElement, useState } from 'react';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { useForm } from 'react-hook-form';

// Styles
import { Button, Input, Label } from 'styles';
import {
  ErrorText,
  ErrorWrapper,
  Form,
  FormContainer,
  InputGroup,
  SignInWrapper,
} from '../local-styles';

// Constants
import { validEmailRegex } from '../constants';

export const Signup = (): ReactElement => {
  const identity = useIdentityContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [formError, setFormError] = useState<boolean>(false);
  const [isSigningUp, setSigningUp] = useState<boolean>(false);

  const onSubmit = async (data) => {
    setSigningUp(true);
    setFormError(false);

    identity
      .signup(data)
      .then(() => {
        setSigningUp(false);
      })
      .catch((e) => {
        setFormError(e.message);
        setSigningUp(false);
      });
  };

  if (identity.provisionalUser) {
    return (
      <FormContainer>
        We have sent a verification email to your email. Please verify your
        account.
      </FormContainer>
    );
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {/* First Name */}
      <div css={InputGroup}>
        <Label htmlFor="user_metadata.firstName">First Name</Label>
        <Input
          {...register('user_metadata.firstName', {
            required: true,
          })}
          type="text"
          placeholder="jane"
          name="user_metadata.firstName"
        />
        {errors.firstName && <p css={ErrorText}>First Name is required</p>}
      </div>

      {/* Last Name */}
      <div css={InputGroup}>
        <Label htmlFor="user_metadata.lastName">Last Name</Label>
        <Input
          {...register('user_metadata.lastName', {
            required: true,
          })}
          type="text"
          placeholder="Doe"
          name="user_metadata.lastName"
        />
        {errors.email && <p css={ErrorText}>Last Name is required</p>}
      </div>

      {/* Email */}
      <div css={InputGroup}>
        <Label htmlFor="email">Email</Label>
        <Input
          {...register('email', {
            required: true,
            pattern: validEmailRegex,
          })}
          type="text"
          placeholder="jane@doe.com"
          name="email"
        />
        {errors.email && <p css={ErrorText}>Email is required</p>}
      </div>

      {/* Password */}
      <div css={InputGroup}>
        <Label htmlFor="password">Password</Label>
        <Input
          {...register('password', { required: true })}
          name="password"
          type="password"
          placeholder="******************"
        />
        {errors.password && <p css={ErrorText}>Password is required</p>}
      </div>

      {/* Action */}
      <div css={SignInWrapper}>
        <Button disabled={isSigningUp} type="submit">
          Sign up
        </Button>
      </div>
      <div css={ErrorWrapper}>
        {formError && <p css={ErrorText}>{formError}</p>}
      </div>
    </Form>
  );
};
